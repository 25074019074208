<template>
    <div v-if="component_done_loading">
        <div class="test_topBar_placeholder" @load="updateTopNavHeight"></div>
        <header ref="testTopBar" class="test_topBar_v2 no-print">
            <div
                class="new_topBar_content"
                :class="{
                    timer: isTimerShown,
                    'd-block': isMobileView,
                }"
            >
                <div
                    :class="{
                        flexSpaceBetween: testObj._isTeacherPreview && isMobileView,
                        textAlignCenter: isMobileView,
                    }"
                >
                    <img src="/img/icons/_newicons_intedashboardlogo.png" width="25px" alt="InteDashboard Logo" />

                    <button v-if="testObj._isTeacherPreview" class="btn btn-default flexInherit" @click="reloadPage">
                        <i class="fas fa-eraser marginRight8" aria-hidden="true" /> Clear Answer
                    </button>
                </div>

                <div
                    v-if="testObj.presentationMode && testObj.status != 'ongoing'"
                    class="test_topBarContent"
                    :class="{
                        flexSpaceBetween: !isMobileView,
                    }"
                >
                    <div
                        :class="{
                            'col-md-11 col-lg-11 flexSpaceBetween align-items': !isMobileView,
                        }"
                    >
                        <h1 class="margin0 test_title">{{ testObj.name }} Presentation Screen</h1>

                        <test-status
                            v-if="['not started', 'paused', 'ongoing', 'ended', 'completed'].includes(testObj.status)"
                            :test="testObj.status"
                            role="topBarStatus"
                            class="widthFitContent heightFitContent flexInherit"
                            :class="{
                                marginTop10: isMobileView,
                            }"
                        />
                    </div>

                    <div
                        :class="{
                            marginTop20: isMobileView,
                            'col-md-1 col-lg-1 textAlignRight': !isMobileView,
                        }"
                    >
                        <router-link v-slot="{ navigate }" :to="{ name: 'tests.index' }" custom>
                            <button class="btn btn-default" @click="navigate"><i class="fas fa-caret-left marginRight8" aria-hidden="true" />Back</button>
                        </router-link>
                    </div>
                </div>

                <div v-else :class="{ 'flex1 flex align-items': !testObj._isTeacherPreview && !isMobileView }">
                    <div class="col-xs-12 col-md-11" :class="{ marginTop20: isMobileView }">
                        <div
                            v-if="!forceHideTimer && testObj.settings.hasTimeLimit"
                            class="col-xs-12 col-md-10 col-md-offset-1 col-lg-6 col-lg-offset-3"
                            :class="{ textAlignCenter: !isMobileView }"
                        >
                            <topbar-timer v-show="isTimerShown" :test-obj="testObj" @is-shown="processTimerShown" />
                        </div>
                    </div>
                    <template v-if="testObj._isTeacherPreview"></template>
                    <template v-else-if="testObj.type != 'evaluation'">
                        <div class="col-xs-12 col-md-1" :class="{ textAlignRight: !isMobileView, marginTop20: isMobileView }">
                            <template v-if="$route.name == 'tests.clarification'">
                                <router-link v-if="$route.meta.previousRoute.name == 'tests.index'" v-slot="{ navigate }" :to="{ name: 'tests.index' }" custom>
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fas fa-caret-left marginRight8" aria-hidden="true" />Back
                                    </button>
                                </router-link>
                                <router-link
                                    v-else
                                    v-slot="{ navigate }"
                                    :to="{
                                        name: 'tests.proper',
                                        params: { id: testObj.uuid },
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fas fa-caret-left marginRight8" aria-hidden="true" />Back
                                    </button>
                                </router-link>
                            </template>
                            <template v-else>
                                <router-link v-slot="{ navigate }" :to="{ name: 'tests.index' }" custom>
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fas fa-caret-left marginRight8" aria-hidden="true" />Back
                                    </button>
                                </router-link>
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        <div class="col-xs-12 col-md-1" :class="{ textAlignRight: !isMobileView }">
                            <router-link v-slot="{ navigate }" :to="{ name: 'tests.index' }" custom>
                                <button class="btn btn-default" @click="navigate"><i class="fas fa-caret-left marginRight8" aria-hidden="true" />Back</button>
                            </router-link>
                        </div>
                    </template>
                </div>
            </div>
            <div v-if="!(testObj.presentationMode && testObj.status != 'ongoing')" class="top-bar-activity-details-container">
                <div class="top-bar-activity-details">
                    <h1 class="fs-18px marginTop0 marginBottom0 marginRight8">
                        <span class="visible-hidden">Activity Type</span>
                        <template v-if="testObj.type == 'prework'"> <span class="visible-hidden">Activity Type</span> Prework </template>
                        <template v-if="testObj.type == 'irat'"> IRAT </template>
                        <template v-if="testObj.type == 'trat'"> TRAT </template>
                        <template v-if="testObj.type == 'iratntrat'"> IRAT & TRAT </template>
                        <template v-if="testObj.type == 'application'"> Application Case </template>
                        <template v-if="testObj.type == 'evaluation'"> 360 Degree Evaluation </template>
                        <template v-if="testObj.type == 'michaelsenEvaluation'"> Peer Evaluation: Michaelsen’s Method </template>
                        | <span class="visible-hidden">Activity Name</span> {{ testObj.name }}
                    </h1>
                    <a v-if="showActivityInstructionsLink" href="#" @click="openInstructions"> View Activity Instructions </a>
                </div>
            </div>
        </header>
    </div>
</template>
<script>
import KrAuth from '../../../../../components/auth/auth';
export default {
    props: ['testObj', 'options'],
    data() {
        return {
            component_done_loading: false,
            isTimerShown: true,
            auth: new KrAuth(),
            forceHideTimer: false,
            displayTestProper: false,
        };
    },
    created() {
        this.forceHideTimer = _.get(this.options, 'forceHideTimer', false);
        this.displayTestProper = _.get(this.options, 'displayTestProper', false);

        window.addEventListener('load', () => {
            this.updateTopNavHeight();
        });
    },
    mounted() {
        var that = this;

        this.component_done_loading = true;

        this.$nextTick(() => {
            this.updateTopNavHeight();
        });

        window.addEventListener('resize', this.updateTopNavHeight);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateTopNavHeight);
    },
    computed: {
        routeName() {
            if (
                ['tests.irat-report', 'tests.trat-report', 'tests.application-report', 'tests.evaluation-report', 'tests.evaluation-report-v2'].includes(
                    this.$route.name
                )
            ) {
                return 'Report for';
            }

            if (['tests.proper'].includes(this.$route.name)) {
                if (this.displayTestProper) {
                    return 'Activity for';
                } else {
                    return 'Instruction for';
                }
            }

            if (['tests.clarification'].includes(this.$route.name)) {
                return 'Clarification for';
            }

            if (['tests.instruction'].includes(this.$route.name)) {
                return 'Instruction for';
            }

            if (['tests.discussion'].includes(this.$route.name)) {
                return 'Discussion for';
            }

            if (['tests.evaluation'].includes(this.$route.name)) {
                return 'Evaluation for';
            }
        },
        showActivityInstructionsLink() {
            if (['tests.prework', 'tests.instruction', 'tests.discussion', 'tests.clarification'].includes(this.$route.name) || !this.displayTestProper) {
                return false;
            }
            return true;
        },
    },
    methods: {
        downloadReport() {
            var that = this;
            axios({
                url: '/student/tests/' + this.testObj.uuid + '/report/grades',
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '(' + that.testObj.type + ')' + that.testObj.name + '_' + moment().format('YYYY_MM_DD') + '.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        processTimerShown(isShown) {
            this.isTimerShown = isShown;
            this.$emit('timer-shown', this.isTimerShown);
        },
        openInstructions() {
            Events.fire('show-stopping-div');
        },
        getDisplayName() {
            return (this.auth.user().displayName && this.auth.user().displayName.trim()) != ''
                ? this.auth.user().displayName
                : this.getStudentName(this.auth.user(), false);
        },
        getStudentName(student, limit = true) {
            var name = student.firstname + (student.lastname ? ' ' + student.lastname : '');
            if (name.length > 20 && limit) {
                return name.slice(0, 17) + '...';
            }
            return name;
        },
        init() {
            var ret = [];
            for (var i = 0; i < this.testObj.questions.length; i++) {
                ret.push(this.testObj.questions[i][0]);
            }

            var j = ret.findIndex((z) => z.uuid === this.testObj.eGalleryProgression.currentQuestionUuid);

            return (this.current_question_data = this.testObj.questions[j][0]);
        },
        updateTopNavHeight() {
            if (!this.$refs.testTopBar) {
                return;
            }
            $('.test_topBar_placeholder').height(this.$refs.testTopBar.offsetHeight + 'px');
        },
        truncate(str, characters = 20) {
            return _.truncate(str, {
                length: characters,
                omission: '...',
            });
        },
        reloadPage() {
            window.location.reload();
        },
    },
    components: {
        'topbar-timer': require(`./topbar-timer.vue`).default,
    },
    watch: {
        options: {
            handler: function () {
                this.forceHideTimer = _.get(this.options, 'forceHideTimer', false);
                this.displayTestProper = _.get(this.options, 'displayTestProper', false);
            },
            deep: true,
        },
    },
};
</script>

<style scoped lang="scss">
.test_topBarContent {
    padding: 15px 25px;
    flex: 1;
}

.test_topBar {
    position: fixed;
    z-index: 200;
    width: 100%;
    top: 0;
    background-color: #23356d;
}

.loadingText {
    font-style: italic;
    text-align: center;
    color: #fff;
}

.test_topBarContent .col-xs-10 {
    text-align: center;
}

.test_title {
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
    color: #fff;
    text-align: left;
}

@media (min-width: 992px) {
    .test_logo {
        padding: 15px 35px;
    }

    .loadingText {
        font-size: 16px;
    }

    .timer_Div {
        margin-bottom: 15px;
    }
    .viewInstructionsEnded {
        margin-top: -25px;
        text-align: center;
    }
}

@media (max-width: 991px) {
    .test_logo {
        padding: 15px;
    }

    .loadingText {
        font-size: 12px;
    }

    .timer_Div {
        margin-bottom: 10px;
    }

    .viewInstructions {
        font-size: 10px;
    }
}

@media (max-width: 480px) {
    .test_topBarContent {
        padding: 15px;
    }
}
</style>
