<template>
    <div class="col-md-3 left_col no-print" :class="{ isStudent: auth.user().role == 'Student' }">
        <aside v-if="showToggle" class="left_col scroll-view">
            <div class="menu-logo-wrapper flexOnly align-items justifyCenter hidden-xs hidden-sm">
                <img src="/img/icons/_newicons_intedashboardlogo.png" width="25" alt="InteDashboard | TBL Makes Teams Work" role="presentation" />
            </div>
            <nav id="sidebar-menu" class="main_menu_side hidden-print main_menu menu_fixed" aria-label="Sidebar Navigator">
                <ul class="nav side-menu">
                    <li v-if="auth.user().role == 'Superuser'">
                        <router-link tag="a" :to="{ name: 'accounts.index' }" aria-labelledby="Accounts">
                            <div class="textAlignCenter">
                                <i class="fa fa-id-card" aria-hidden="true"></i>
                                <p>{{ $t('modules.accounts.moduleLabel') }}</p>
                            </div>
                        </router-link>
                    </li>

                    <li v-if="auth.user().role == 'Superuser'">
                        <router-link tag="a" :to="{ name: 'superusers.index' }" aria-labelledby="Superuser">
                            <div class="menu-container textAlignCenter">
                                <i class="fas fa-crown" aria-hidden="true"></i>
                                <p>{{ $t('modules.superusers.moduleLabel') }}</p>
                            </div>
                        </router-link>
                    </li>

                    <li v-if="auth.user().role == 'Teacher' || auth.user().role == 'Super Admin'">
                        <router-link tag="a" :to="{ name: 'courses.index' }" aria-labelledby="Courses">
                            <div class="menu-container textAlignCenter">
                                <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                                <p>Courses</p>
                            </div>
                        </router-link>
                    </li>

                    <li v-if="auth.user().role == 'Super Admin' || auth.user().role == 'Teacher'">
                        <router-link tag="a" :to="{ name: 'people.index' }" aria-labelledby="Teachers">
                            <div class="menu-container textAlignCenter">
                                <i class="fa fa-users" aria-hidden="true"></i>
                                <p>People</p>
                            </div>
                        </router-link>
                    </li>

                    <li
                        v-if="
                            (auth.user().role == 'Teacher' || auth.user().role == 'Super Admin') &&
                            auth.user().account.version == 'full' &&
                            !(auth.user().canViewQuestionBank == false)
                        "
                    >
                        <router-link tag="a" :to="{ name: 'questions.index' }" aria-labelledby="Library">
                            <div class="menu-container textAlignCenter">
                                <i class="fa fa-list-ul" aria-hidden="true"></i>
                                <p>Library</p>
                            </div>
                        </router-link>
                    </li>

                    <li v-if="auth.user().role == 'Student'">
                        <router-link tag="a" :to="{ name: 'tests.index' }">
                            <div class="menu-container textAlignCenter" aria-labelledby="Activities">
                                <i class="fas fa-pencil-ruler" aria-hidden="true"></i>
                                <p>Activities</p>
                            </div>
                        </router-link>
                    </li>

                    <li v-if="auth.user().role == 'Student'">
                        <router-link tag="a" :to="{ name: 'grades.index' }">
                            <div class="menu-container textAlignCenter" aria-labelledby="Grades">
                                <i class="fa fa-book" aria-hidden="true"></i>
                                <p>Gradebook</p>
                            </div>
                        </router-link>
                    </li>

                    <li v-if="auth.user().role == 'Superuser'">
                        <router-link tag="a" :to="{ name: 'report' }" aria-labelledby="Report">
                            <div class="menu-container textAlignCenter">
                                <i class="fas fa-file-alt" aria-hidden="true"></i>
                                <p>{{ $t('modules.reports.moduleLabel') }}</p>
                            </div>
                        </router-link>
                    </li>

                    <li v-if="auth.user().role == 'Super Admin'">
                        <router-link tag="a" :to="{ name: 'settings.edit' }" aria-labelledby="Report">
                            <div class="menu-container textAlignCenter">
                                <!-- <i class="fas fa-file-alt"></i> -->
                                <i class="fa fa-wrench" aria-hidden="true"></i>
                                <p>Settings</p>
                            </div>
                        </router-link>
                    </li>

                    <li v-if="auth.user().roleId == 2 || auth.user().roleId == 3">
                        <a href="https://community.intedashboard.com/" target="_blank">
                            <div class="menu-container textAlignCenter">
                                <span class="material-icons" aria-hidden="true"> forum </span>
                                <p>Community</p>
                            </div>
                        </a>
                    </li>
                </ul>

                <ul class="nav side-menu help-center">
                    <li>
                        <a
                            :href="
                                auth.user().role == 'Student'
                                    ? 'https://help.intedashboard.com/contact-intedashboard-support'
                                    : 'https://help.intedashboard.com/learner'
                            "
                            target="_blank"
                            aria-label="Help Guides"
                        >
                            <div class="menu-container textAlignCenter">
                                <i class="fas fa-question-circle" aria-hidden="true"></i>
                                <p>Help</p>
                            </div>
                        </a>
                    </li>
                </ul>
            </nav>
        </aside>
    </div>
</template>
<script>
import KrAuth from '../../../../components/auth/auth.js';
import swal from 'sweetalert2';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            showToggle: false,
            //VUE_APP_ENV: process.env.VUE_APP_ENV,
        };
    },
    watch: {
        $route: {
            handler: function () {
                if ($(window).width() < 991) {
                    this.toggleMenu();
                }
            },
        },
    },
    created() {
        var that = this;
        if (this.$route.name == 'tests.preview') {
            this.showToggle = false;
        } else {
            this.showToggle = true;
        }
    },
    mounted() {
        if ($(window).width() < 991) {
            this.toggleMenu();
        }
    },
    methods: {
        logout() {
            swal({
                title: 'Are you sure you want to logout?',
                //text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            })
                .then((result) => {
                    Events.fire('logout', { redirect: { name: 'auth.login' } });
                })
                .catch((result) => {});
        },
        toggleMenu() {
            var $BODY = $('body'),
                $RIGHT_COL = $('.right_col'),
                $SIDEBAR_MENU = $('#sidebar-menu'),
                $NAV_MENU = $('.nav_menu'),
                $LEFT_COL = $('.left_col'),
                $SIDEBAR_FOOTER = $('.sidebar-footer'),
                $FOOTER = $('footer');
            if ($BODY.hasClass('nav-sm')) {
                $SIDEBAR_MENU.find('li.active ul').hide();
                $SIDEBAR_MENU.find('li.active').addClass('active-sm').removeClass('active');
            } else {
                $SIDEBAR_MENU.find('li.active-sm ul').show();
                $SIDEBAR_MENU.find('li.active-sm').addClass('active').removeClass('active-sm');
            }
            $BODY.toggleClass('nav-sm nav-none');
            // reset height
            $RIGHT_COL.css('min-height', $(window).height());
            var bodyHeight = $BODY.outerHeight(),
                footerHeight = $BODY.hasClass('footer_fixed') ? 0 : $FOOTER.height(),
                leftColHeight = $LEFT_COL.eq(1).height() + $SIDEBAR_FOOTER.height(),
                contentHeight = bodyHeight < leftColHeight ? leftColHeight : bodyHeight;
            // normalize content
            contentHeight -= $NAV_MENU.height() + footerHeight;
            $RIGHT_COL.css('min-height', contentHeight);
        },
    },
};
</script>
<style scoped lang="scss">
.nav.side-menu {
    background: #495060;

    & > li a {
        padding: 12px 8px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 6px solid transparent;
        color: #ffffff;
        background: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        text-shadow: none;

        &:hover {
            background: #ffffff;
            color: #0071be;
            cursor: pointer;
            border-left: 6px solid #0071be30;
        }

        &.active {
            margin-left: 0;
            border-left: 6px solid #0071be;
            border-right: none;
            background: #ffffff;
            color: #0071be;
        }
    }
}

.menu-logo-wrapper {
    padding: 10px;
}

@media (max-width: 991px) {
    .menu-logo-wrapper {
        padding: 10px 25px 10px;
        color: #fff;
        font-size: 24px;
        line-height: 1;
        text-align: center;
    }
}

.help-center {
    position: fixed;
    bottom: 0;
    width: 110px;
}

.isStudent {
    .left_col {
        background: #0071be;
        height: 100%;
    }

    .nav {
        &.side-menu {
            background: none;
        }
    }
}
</style>
