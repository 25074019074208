<template>
    <template v-if="selectedCriteria.question.type == 'ratingV2'">
        <template v-for="(stem, stemIdx) in selectedCriteria.question.questionStems">
            <div class="accordion-item-light-primary-dashboard borderRadius0" :class="{ marginTop20: stemIdx != 1 }">
                <div :id="'rating-' + stemIdx" class="accordion-header">
                    <div class="flex align-items">
                        <span class="material-icons marginRight8" aria-hidden="true"> star </span>
                        <label class="fw-normal"> {{ stemIdx }}. {{ selectedCriteria.question.questionStems[stemIdx].stem }} </label>
                        <template v-if="stem.isRequired">
                            <span class="visible-hidden">Required</span>
                            <span class="important" aria-hidden="true">*</span>
                        </template>
                    </div>
                </div>
                <div class="accordion-content" :aria-labelledby="'rating-' + stemIdx">
                    <div v-if="testObj.evaluationSettings.target != 'self' && selectedCriteria.question.settings.answerQuestionsForEachTeammate">
                        <div class="table-responsive newEvaluationTable individualTableV2">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th rowspan="3" class="width150px">Assessee</th>
                                        <th rowspan="3" class="width150px">Status</th>
                                        <th
                                            :colspan="
                                                ratingHaveDescription
                                                    ? 3 * testObj.teams.students[selectedCriteria.teamId].length
                                                    : 2 * testObj.teams.students[selectedCriteria.teamId].length
                                            "
                                        >
                                            Assessors
                                        </th>
                                        <th rowspan="3">Total</th>
                                        <th rowspan="3">Avg</th>
                                        <th rowspan="3">Team Avg</th>
                                    </tr>

                                    <tr>
                                        <template v-for="(student, studentIdx) in testObj.teams.students[selectedCriteria.teamId]">
                                            <th :colspan="ratingHaveDescription ? 3 : 2" class="whiteSpaceNowrap">
                                                {{ student.displayName }}
                                            </th>
                                        </template>
                                    </tr>
                                    <tr>
                                        <template v-for="(student, studentIdx) in testObj.teams.students[selectedCriteria.teamId]">
                                            <th class="whiteSpaceNowrap">Scale</th>
                                            <th class="whiteSpaceNowrap">Label</th>
                                            <th
                                                v-if="ratingHaveDescription"
                                                class="minWidth250px maxWidth250px width250px whiteSpaceNowrap"
                                                :class="{
                                                    withDescription: ratingHaveDescription,
                                                }"
                                            >
                                                Description
                                            </th>
                                        </template>
                                    </tr>
                                </thead>

                                <tbody>
                                    <template v-for="(student, studentIdx) in testObj.teams.students[selectedCriteria.teamId]">
                                        <tr :class="{ notFirstRow: studentIdx != 0, isFirstRow: studentIdx == 0 }">
                                            <td>{{ student.displayName }}</td>

                                            <td>
                                                <!-- <div v-if="individual.status.status=='Timed Out'" class="tableStatusTag tableStatusTag-danger">
                                                Timed Out
                                            </div>
                                            <div v-if="individual.status.status=='Not Started'" class="tableStatusTag tableStatusTag-danger">
                                                Not Started
                                            </div>
                                            <div v-if="individual.status.status=='Ongoing'" class="tableStatusTag tableStatusTag-warning">
                                                Ongoing
                                            </div>
                                            <div v-if="individual.status.status=='Submitted'" class="tableStatusTag tableStatusTag-success">
                                                Submitted
                                            </div>
                                            <div v-if="individual.status.tally" class="marginTop10">{{individual.status.tally}}</div> -->
                                                <template v-if="selectedCriteria.studentStatus.statusPerStudent[student.id] != undefined">
                                                    {{ selectedCriteria.studentStatus.statusPerStudent[student.id] }} /
                                                    {{ selectedCriteria.studentStatus.totalQuestions }} Answered
                                                </template>
                                                <template v-else> Not Started </template>
                                            </td>

                                            <template v-for="(assessor, assessorIdx) in testObj.teams.students[selectedCriteria.teamId]">
                                                <td>
                                                    {{ getAnswerFromGiverAndReceiverId(selectedCriteria.questionStems[stemIdx], assessor.id, student.id) }}
                                                </td>

                                                <td>
                                                    <template
                                                        v-if="
                                                            getAnswerFromGiverAndReceiverId(selectedCriteria.questionStems[stemIdx], assessor.id, student.id) !=
                                                            '-'
                                                        "
                                                    >
                                                        <template
                                                            v-if="
                                                                getRatingObjectFromWeight(
                                                                    selectedCriteria.question,
                                                                    getAnswerFromGiverAndReceiverId(
                                                                        selectedCriteria.questionStems[stemIdx],
                                                                        assessor.id,
                                                                        student.id
                                                                    )
                                                                ).label
                                                            "
                                                        >
                                                            {{
                                                                getRatingObjectFromWeight(
                                                                    selectedCriteria.question,
                                                                    getAnswerFromGiverAndReceiverId(
                                                                        selectedCriteria.questionStems[stemIdx],
                                                                        assessor.id,
                                                                        student.id
                                                                    )
                                                                ).label
                                                            }}
                                                        </template>
                                                        <template v-else> - </template>
                                                    </template>
                                                    <template v-else> - </template>
                                                </td>

                                                <td v-if="ratingHaveDescription">
                                                    <template
                                                        v-if="
                                                            getAnswerFromGiverAndReceiverId(selectedCriteria.questionStems[stemIdx], assessor.id, student.id) !=
                                                            '-'
                                                        "
                                                    >
                                                        <template
                                                            v-if="
                                                                getRatingObjectFromWeight(
                                                                    selectedCriteria.question,
                                                                    getAnswerFromGiverAndReceiverId(
                                                                        selectedCriteria.questionStems[stemIdx],
                                                                        assessor.id,
                                                                        student.id
                                                                    )
                                                                ).rubric
                                                            "
                                                        >
                                                            {{
                                                                getRatingObjectFromWeight(
                                                                    selectedCriteria.question,
                                                                    getAnswerFromGiverAndReceiverId(
                                                                        selectedCriteria.questionStems[stemIdx],
                                                                        assessor.id,
                                                                        student.id
                                                                    )
                                                                ).rubric
                                                            }}
                                                        </template>
                                                        <template v-else> - </template>
                                                    </template>
                                                    <template v-else> - </template>
                                                </td>
                                            </template>

                                            <td>
                                                {{ get(selectedCriteria, `summary.perReceiver.${stemIdx}.${student.id}.totalPoints`, '-') }}
                                            </td>
                                            <td>
                                                {{ get(selectedCriteria, `summary.perReceiver.${stemIdx}.${student.id}.average`, '-') }}
                                            </td>
                                            <td v-if="studentIdx == 0" :rowspan="testObj.teams.students[selectedCriteria.teamId].length">
                                                {{ get(selectedCriteria, `summary.teamAverage.${stemIdx}`, '-') }}
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-else class="table-responsive newEvaluationTable">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Assessors</th>
                                    <th class="whiteSpaceNowrap">Scale</th>
                                    <th class="whiteSpaceNowrap">Label</th>
                                    <th v-if="ratingHaveDescription" class="minWidth250px maxWidth250px width250px whiteSpaceNowrap">Description</th>
                                    <th class="minWidth100px maxWidth100px width100px">Total</th>
                                    <th v-if="selectedCriteria.question.settings.answerQuestionsForEachTeammate" class="minWidth100px maxWidth100px width100px">
                                        Avg
                                    </th>
                                    <th class="minWidth100px maxWidth100px width100px">Team Avg</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(student, studentIdx) in testObj.teams.students[selectedCriteria.teamId]">
                                    <tr>
                                        <td>{{ student.displayName }}</td>
                                        <td>
                                            {{ getAnswerFromGiverAndReceiverId(selectedCriteria.questionStems[stemIdx], student.id, null) }}
                                        </td>
                                        <td>
                                            <template v-if="getAnswerFromGiverAndReceiverId(selectedCriteria.questionStems[stemIdx], student.id, null) != '-'">
                                                <template
                                                    v-if="
                                                        getRatingObjectFromWeight(
                                                            selectedCriteria.question,
                                                            getAnswerFromGiverAndReceiverId(selectedCriteria.questionStems[stemIdx], student.id, null)
                                                        ).label
                                                    "
                                                >
                                                    {{
                                                        getRatingObjectFromWeight(
                                                            selectedCriteria.question,
                                                            getAnswerFromGiverAndReceiverId(selectedCriteria.questionStems[stemIdx], student.id, null)
                                                        ).label
                                                    }}
                                                </template>
                                                <template v-else> - </template>
                                            </template>
                                            <template v-else> - </template>
                                        </td>
                                        <td v-if="ratingHaveDescription">
                                            <template v-if="getAnswerFromGiverAndReceiverId(selectedCriteria.questionStems[stemIdx], student.id, null) != '-'">
                                                <template
                                                    v-if="
                                                        getRatingObjectFromWeight(
                                                            selectedCriteria.question,
                                                            getAnswerFromGiverAndReceiverId(selectedCriteria.questionStems[stemIdx], student.id, null)
                                                        ).rubric
                                                    "
                                                >
                                                    {{
                                                        getRatingObjectFromWeight(
                                                            selectedCriteria.question,
                                                            getAnswerFromGiverAndReceiverId(selectedCriteria.questionStems[stemIdx], student.id, null)
                                                        ).rubric
                                                    }}
                                                </template>
                                                <template v-else> - </template>
                                            </template>
                                            <template v-else> - </template>
                                        </td>
                                        <td>
                                            {{ get(selectedCriteria, `summary.perGiver.${stemIdx}.${student.id}.totalPoints`, '-') }}
                                        </td>
                                        <td v-if="selectedCriteria.question.settings.answerQuestionsForEachTeammate">
                                            {{ get(selectedCriteria, `summary.perGiver.${stemIdx}.${student.id}.average`, '-') }}
                                        </td>
                                        <td v-if="studentIdx == 0" :rowspan="testObj.teams.students[selectedCriteria.teamId].length">
                                            {{ get(selectedCriteria, `summary.teamAverage.${stemIdx}`, '-') }}
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </template>
    </template>

    <div v-if="selectedCriteria.question.type == 'pointDistribution'" class="table-responsive newEvaluationTable">
        <table class="table">
            <thead>
                <tr>
                    <th rowspan="2" class="width120px minWidth120px maxWidth120px">Assessee</th>
                    <th rowspan="2" class="width150px">Status</th>
                    <th :colspan="testObj.teams.students[selectedCriteria.teamId].length">Assessors</th>
                    <th rowspan="2" class="width73px minWidth73px maxWidth73px">Total</th>
                    <th rowspan="2" class="width73px minWidth73px maxWidth73px">Avg</th>
                    <th rowspan="2" class="width75px minWidth75px maxWidth75px">
                        <span class="flex align-items" aria-label="Peer Assessment Factor Beta">
                            PAF
                            <a
                                v-tooltip="'Peer Assessment Factor Information'"
                                href="https://help.intedashboard.com/how-to-interpret-peer-assessment-factor-paf"
                                class="marginLeft5"
                                target="_blank"
                            >
                                <i class="fa fa-info-circle" aria-label="Peer Assessment Factor Information" />
                            </a>
                        </span>
                    </th>
                    <th rowspan="2" class="width78px minWidth78px maxWidth78px">
                        <span class="flex align-items" aria-label="Self Assessment Over Peer Assessment Beta">
                            SAPA

                            <a
                                v-tooltip="'Self Assessment Over Peer Assessment Information'"
                                href="https://help.intedashboard.com/use-of-sapa-self-assessment-over-peer-assessment"
                                class="marginLeft5"
                                target="_blank"
                            >
                                <i class="fa fa-info-circle" aria-label="Self Assessment Over Peer Assessment Information" />
                            </a>
                        </span>
                    </th>
                    <th rowspan="2" class="width73px minWidth73px maxWidth73px">Team Avg</th>
                </tr>
                <tr>
                    <template v-for="(assessor, assessorIdx) in testObj.teams.students[selectedCriteria.teamId]">
                        <th class="whiteSpaceNowrap">{{ assessor.displayName }}</th>
                    </template>
                </tr>
            </thead>

            <tbody>
                <template v-for="(student, studentIdx) in testObj.teams.students[selectedCriteria.teamId]">
                    <tr :class="{ notFirstRow: studentIdx != 0 }">
                        <td>{{ student.displayName }}</td>
                        <td>
                            <template v-if="selectedCriteria.studentStatus.statusPerStudent[student.id] != undefined">
                                {{ selectedCriteria.studentStatus.statusPerStudent[student.id] }} / {{ selectedCriteria.studentStatus.totalQuestions }} Answered
                            </template>
                            <template v-else> Not Started </template>
                        </td>
                        <template v-for="(assessor, assessorIdx) in testObj.teams.students[selectedCriteria.teamId]">
                            <td>
                                {{ getAnswerFromGiverAndReceiverId(selectedCriteria.pointDistributions[student.id], assessor.id, student.id) }}
                            </td>
                        </template>
                        <td>{{ get(selectedCriteria, `summary.perReceiver.${student.id}.totalPoints`, '-') }}</td>
                        <td>{{ get(selectedCriteria, `summary.perReceiver.${student.id}.average`, '-') }}</td>
                        <td>{{ get(selectedCriteria, `summary.perReceiver.${student.id}.paf`, '-') }}</td>
                        <td>{{ get(selectedCriteria, `summary.perReceiver.${student.id}.sapa`, '-') }}</td>
                        <td v-if="studentIdx == 0" :rowspan="testObj.teams.students[selectedCriteria.teamId].length">
                            {{ get(selectedCriteria, 'summary.teamAverage', '-') }}
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>

    <template v-if="selectedCriteria.question.type == 'openendedV2'">
        <div
            v-if="testObj.evaluationSettings.target != 'self' && selectedCriteria.question.settings.answerQuestionsForEachTeammate"
            class="table-responsive newEvaluationTable"
        >
            <table class="table">
                <thead>
                    <tr class="light-blue-bg">
                        <th>Assessee</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(student, studentIdx) in testObj.teams.students[selectedCriteria.teamId]">
                        <tr class="light-orange-bg">
                            <td>{{ student.displayName }}</td>
                            <td>
                                <div class="tableStatusTag displayInlineBlock">
                                    <template v-if="selectedCriteria.studentStatus.statusPerStudent[student.id] != undefined">
                                        {{ selectedCriteria.studentStatus.statusPerStudent[student.id] }} /
                                        {{ selectedCriteria.studentStatus.totalQuestions }} Answered
                                    </template>
                                    <template v-else> Not Started </template>
                                </div>
                            </td>
                        </tr>
                        <tr ref="management1-Content">
                            <td colspan="2" class="sub-table">
                                <template v-for="(stem, stemIdx) in selectedCriteria.question.questionStems">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th colspan="3">{{ stemIdx }}. {{ stem.stem }}</th>
                                            </tr>
                                            <tr>
                                                <th class="minWidth200px width200px maxWidth200px">Assessor</th>
                                                <th>Response</th>
                                                <th class="minWidth266px width266px maxWidth266px">Sentiment</th>
                                                <!-- <th class="minWidth400px width400px maxWidth400px">Instructor Feedback</th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(assessor, assessorIdx) in testObj.teams.students[selectedCriteria.teamId]">
                                                <tr>
                                                    <td :class="{ disabled: studentIdx == assessorIdx }">
                                                        {{ assessor.displayName }}
                                                    </td>
                                                    <td class="whiteSpacePreWrap" :class="{ disabled: studentIdx == assessorIdx }">
                                                        {{ getAnswerFromGiverAndReceiverId(selectedCriteria.questionStems[stemIdx], assessor.id, student.id) }}
                                                    </td>
                                                    <td
                                                        :class="{ disabled: studentIdx == assessorIdx }"
                                                        v-html="
                                                            getSentiment(
                                                                getAnswerFromGiverAndReceiverId(
                                                                    selectedCriteria.questionStems[stemIdx],
                                                                    assessor.id,
                                                                    student.id
                                                                )
                                                            )
                                                        "
                                                    />
                                                    <!-- <td :class="{ disabled: studentIdx == assessorIdx }">
                                                        <Field v-if="studentIdx != assessorIdx" type="text">
                                                            <input placeholder="Type comment" class="form-control zIndex1" />
                                                        </Field>
                                                        <template v-else> - </template>
                                                    </td> -->
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </template>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <template v-else>
            <template v-for="(stem, stemIdx) in selectedCriteria.question.questionStems">
                <div class="accordion-item-light-primary-dashboard borderRadius0" :class="{ marginTop20: stemIdx != 1 }">
                    <div :id="'openEnded-' + stemIdx" class="accordion-header">
                        <div class="inlineFlex">
                            <span class="material-icons marginRight8" aria-hidden="true"> format_align_left </span>

                            <label class="fw-normal">
                                {{ stemIdx }}. {{ stem.stem }}

                                <template v-if="stem.isRequired">
                                    <span class="visible-hidden">Required</span>
                                    <span class="important" aria-hidden="true">*</span>
                                </template>
                            </label>
                        </div>
                    </div>
                    <div class="accordion-content" :aria-labelledby="'openEnded-' + stemIdx">
                        <div class="table-responsive newEvaluationTable">
                            <div class="sub-table padding0">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="minWidth200px width200px maxWidth200px">Assessor</th>
                                            <th>Response</th>
                                            <th class="minWidth266px width266px maxWidth266px">Sentiment</th>
                                            <!-- <th class="minWidth400px width400px maxWidth400px">Instructor Feedback</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(student, studentIdx) in testObj.teams.students[selectedCriteria.teamId]">
                                            <tr>
                                                <td>
                                                    {{ student.displayName }}
                                                </td>
                                                <td class="whiteSpacePreWrap">
                                                    {{ getAnswerFromGiverAndReceiverId(selectedCriteria.questionStems[stemIdx], student.id, null) }}
                                                </td>
                                                <td
                                                    v-html="
                                                        getSentiment(
                                                            getAnswerFromGiverAndReceiverId(selectedCriteria.questionStems[stemIdx], assessor.id, student.id)
                                                        )
                                                    "
                                                />
                                                <!-- <td>
                                                    <Field type="text">
                                                        <input placeholder="Type comment" class="form-control zIndex1" />
                                                    </Field>
                                                </td> -->
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </template>
</template>

<script setup>
import { computed } from 'vue';
const props = defineProps({
    testObj: {
        type: Object,
        default: () => ({}),
    },
    selectedCriteria: {
        type: Object,
        default: () => ({}),
    },
});

const getRatingObjectFromWeight = (question, weight) => {
    let idx = _.findIndex(question.ratingSettings, function (o) {
        return o.weight == weight;
    });

    if (idx != -1) {
        return question.ratingSettings[idx];
    } else return null;
};

const getAnswerFromGiverAndReceiverId = (answers, giverId, receiverId) => {
    let idx = _.findIndex(answers, function (o) {
        if (giverId && receiverId) {
            return o.giverId == giverId && o.receiverId == receiverId;
        } else if (giverId && !receiverId) {
            return o.giverId == giverId;
        } else if (!giverId && receiverId) {
            return o.receiverId == receiverId;
        }
    });

    if (idx != -1) {
        return answers[idx].answer;
    } else return '-';
};

const get = (obj, dotNotation, defaultValue) => {
    let ans = _.get(obj, dotNotation, null);
    if (ans === null) {
        return defaultValue;
    }
    return ans;
};

const ratingHaveDescription = computed(() => {
    if (props.selectedCriteria.question.type == 'ratingV2') {
        for (var i = 0; i < props.selectedCriteria.question.ratingSettings.length; i++) {
            if (props.selectedCriteria.question.ratingSettings[i].rubric) {
                return true;
            }
        }
    }
    return false;
});
</script>
