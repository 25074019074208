import { createApp } from 'vue';
import { v4 as uuidv4 } from 'uuid';
require('./bootstrap');
import Vue from 'vue';
//import Notifications from 'vue-notification';
import VueClipboard from 'vue3-clipboard';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import Notifications from '@kyvg/vue3-notification';
import VuePapaParse from 'vue-papa-parse';
//import VueSanitize from 'vue-sanitize';

import Tooltip from 'v-tooltip';

window._ = require('lodash');
window.$ = window.jQuery = require('jquery');
//require('chartjs-chart-box-and-violin-plot/build/Chart.BoxPlot.mjs')
import 'chartjs-chart-box-and-violin-plot/build/Chart.BoxPlot.js';

window.MathJax = {
    chtml: {
        fontURL: process.env.VUE_APP_APP_URL + '/mathjax/output/chtml/fonts/woff-v2', // The URL where the fonts are found
    },
};
require('mathjax/es5/tex-mml-chtml.js');
/*require('mathjax/jax/output/SVG/config.js?V=2.7.5')
require('mathjax/config/MML_HTMLorMML.js?V=2.7.5')
require('mathjax/jax/output/SVG/jax.js?V=2.7.5')
require('mathjax/jax/output/SVG/fonts/TeX/fontdata.js?V=2.7.5')
require('mathjax/jax/output/SVG/fonts/TeX/Size1/Regular/Main.js?V=2.7.5')
require('mathjax/extensions/MathMenu.js?V=2.7.5')
require('mathjax/extensions/MathZoom.js?V=2.7.5')
require('mathjax/extensions/MathML/content-mathml.js?V=2.7.5')
require('mathjax/extensions/MathML/mml3.js?V=2.7.5')*/
/*MathJax.Hub.Config({
  MathML: {
    extensions: ["content-mathml.js","mml3.js"]
  }
});*/
import 'jquery-ui/ui/widgets/draggable.js';
import 'jquery-ui/ui/widgets/resizable.js';
/*Font Awesome*/
window.Vue = require('vue').default;
//Vue.use(VTooltip);
//Vue.use(Notifications);
//Vue.use(VueClipboard);
//Vue.component(VueQrcode.name, VueQrcode);
//Vue.use(VuePapaParse);
//Vue.use(VueSanitize);
//Vue.use(VueMouseflow, { tracking_key: process.env.VUE_APP_MOUSEFLOW_KEY});
require('vue-resource');
require('bootstrap-sass');
//Vue.use(window.ShortKey);
/*Dedicated Bus to handle events Parent to non Parent*/
window.Events = new (class {
    constructor() {
        this.vue = new Vue();
    }
    fire(event, data = null) {
        this.vue.$emit(event, data);
    }
    listen(event, callback) {
        this.vue.$on(event, callback);
    }
    off(event) {
        this.vue.$off(event);
    }
})();

window.axios = require('axios');
require('./axios_config');
require('./components/ui/bootstrap');
require('./components/forms/bootstrap');
require('./components/search/bootstrap');
require('./components/misc/bootstrap');

require('./components/zoom/bootstrap');

window.moment = require('moment');

//global mixins
import { globalMixins } from './mixins/global';
import App from './App';
import { router } from './router';
import { createGtm } from '@gtm-support/vue-gtm';
import KrAuth from './components/auth/auth.js';
import KrEcho from './components/echo/echo.js';
import { getGTMId } from './components/gtm/gtm.js';
if (process.env.VUE_APP_DEV_TOOLS == 'false') {
    Vue.config.devtools = false;
} else {
    Vue.config.devtools = true;
}
import * as Sentry from '@sentry/vue';
import { pinia } from './stores';
import { useAuthUserStore } from './stores/auth';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import i18n from './i18n';
pinia.use(piniaPluginPersistedstate);
const app = createApp({
    data() {
        let auth = new KrAuth();
        let echo = new KrEcho();
        return {
            auth: auth,
            echo: echo,
            authStore: useAuthUserStore(),
        };
    },
    created() {
        var that = this;
        this.authStore.setSessionUuid(uuidv4());
        // Redirect
        if (location.host == 'demo.intedashboard.com') {
            window.location = window.location.href.replace(window.location.origin, 'https://v3.intedashboard.com');
        }

        //test
        if (!this.auth.isLoggedIn()) {
            if (this.$route.query.access_token && this.$route.query.api_url) {
                axios
                    .post(
                        this.$route.query.api_url + '/auth/validate',
                        { TzOffset: new Date().getTimezoneOffset() },
                        {
                            headers: {
                                Authorization: `Bearer ${this.$route.query.access_token}`,
                            },
                        }
                    )
                    .then(function (response) {
                        that.auth.login({
                            ...response.data,
                            remember: that.$route.query.remember,
                        });
                    })
                    .catch(function (err) {
                        //window.location.href =
                        //process.env.VUE_APP_AUTH_URL + '/logout';
                    });
            } else {
                window.location.href = process.env.VUE_APP_AUTH_URL + '/logout';
            }
        } else {
            this.echo.connect(this.auth.credential());
            this.$gtm.enable(true);
            this.processEchoListeners();
        }
    },
    mounted() {
        var that = this;
        //Login,Logout, And Refresh User Information Events
        if (this.auth.isLoggedIn()) {
            //Sentry.setUser({userUuid:this.auth.user().uuid});
        }
        if (this.auth.isLoggedIn() && this.auth.user().role == 'Student' && this.$route.name != 'checkout-success') {
            // axios.get('student/current-status').then(function (response) {});
        }
        Events.listen('getUserInformation', () => {
            this.getUserInformation();
        });
        Events.listen('login-successful', (options) => {
            this.$gtm.id = getGTMId(this.auth);
            this.$gtm.enable(true);
            this.setMomentOffset(this.auth.credential().serverTimeOnLogin);
            this.echo.connect(this.auth.credential());
            this.processEchoListeners();

            if (this.auth.isLoggedIn()) {
                axios.defaults.baseURL = this.auth.credential().API_URL;
                //Sentry.setUser({userUuid:this.auth.user().uuid});
            }
            //options from auth.js to store.js to here.
            ////{url:{redirect:'true',route:false}}
            /*Redirect if unverified*/
            if (_.get(options, 'url.redirect', true)) {
                if (_.get(options, 'url.route', false) == false) {
                    if (this.auth.user().role == 'Superuser') {
                        this.$router.push({ name: 'accounts.index' });
                    }
                    if (this.auth.user().role == 'Teacher') {
                        this.$router.push({ name: 'courses.index' });
                    }
                    if (this.auth.user().role == 'Super Admin') {
                        this.$router.push({ name: 'courses.index' });
                    }
                    if (this.auth.user().role == 'Student') {
                        this.$router.push({ name: 'tests.index' });
                    }
                } else {
                    this.$router.push(options.url.route);
                }
            } else {
                //no redirects just log in!
                //for checkouts!
            }
        });
        Events.listen('impersonate-login-successful', (options = {}) => {
            this.$router.push({ name: 'courses.index' });
        });
        Events.listen('logout', (options = {}) => {
            this.logout(options);
        });
        Events.listen('impersonate-logout-successful', () => {
            if (this.auth.user().role == 'Superuser') {
                this.$router.push({ name: 'accounts.index' });
            }
        });
        Events.listen('logout-successful', (options = {}) => {
            //Sentry.setUser(null);
            window.location.href = process.env.VUE_APP_AUTH_URL + '/logout';
        });

        if (this.auth.user().role == 'Account Admin') {
            this.logout();
        }
        /*window.addEventListener(
      "beforeunload",
      function(event) {
        that.$store.dispatch("removeTab");
      },
      false
    );
    window.addEventListener("unload", function(e) {
      this.alert("tae");
      alert("last tab, remember?: " + that.$store.getters.auth().remember);
      if (that.$store.getters.tabs.length == 1) {
        alert("last tab, remember?: " + that.$store.getters.auth().remember);
      }
    });*/

        var highlightingColor = window.localStorage.getItem('highlightColorLocal');

        if (highlightingColor == '#17244A') {
            document.documentElement.style.setProperty('--dark-highlight-color', '#fff');
        } else {
            document.documentElement.style.setProperty('--dark-highlight-color', '#222');
        }

        document.documentElement.style.setProperty('--highlight-color', highlightingColor);
    },
    methods: {
        processEchoListeners() {
            window.Echo.private(`user.${this.auth.user().uuid}`).listen('DownloadableStatusUpdated', (e) => {
                let title = 'Success';
                if (e.type == 'error') {
                    title = 'Error';
                }
                this.$notify({
                    group: 'download-report',
                    type: e.type,
                    title: title,
                    text: e.message,
                    duration: -1,
                    data: e,
                    closeOnclick: false,
                });
            });
            window.Echo.private(`user.${this.auth.user().uuid}`).listen('StatusChecked', (e) => {
                this.authStore.status = e.status;
            });
            window.Echo.private(`user.${this.auth.user().uuid}`).listen('TeacherUpdated', (e) => {
                this.authStore.client.profile.canViewQuestionBank =
                    e.user.can_view_question_bank != null ? e.user.can_view_question_bank : e.user.canViewQuestionBank;
            });
        },
        logout(options = {}) {
            var that = this;
            if (options.from != 'AXIOS_401') {
                if (!this.auth.isImpersonation()) {
                    axios
                        .get('/profile/sign-out')
                        .then(function (response) {})
                        .catch(function (errors) {})
                        .finally(function () {
                            that.auth.logout();
                        });
                } else {
                    axios
                        .post(`auth/invalidate?impersonate&impersonator=${this.auth.user(true).uuid}`)
                        .then(function (response) {})
                        .catch(function (errors) {})
                        .finally(function () {
                            that.auth.logout();
                        });
                }
            } else {
                this.auth.logout();
            }
        },
    },
    render: (h) => h(App),
});
Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE ? process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE : 1,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [process.env.VUE_APP_APP_URL],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: process.env.VUE_APP_REPLAYS_SESSION_SAMPLE_RATE ? process.env.VUE_APP_REPLAYS_SESSION_SAMPLE_RATE : 0.1,
    replaysOnErrorSampleRate: process.env.VUE_APP_REPLAYS_ON_ERROR_SAMPLE_RATE ? process.env.VUE_APP_REPLAYS_ON_ERROR_SAMPLE_RATE : 1,
});

app.component(VueQrcode.name, VueQrcode);
app.use(VueClipboard, {
    autoSetContainer: true,
});
app.use(Notifications);
app.use(router);
app.use(pinia);
app.use(VuePapaParse);
app.use(Tooltip);
app.mixin(globalMixins);
app.use(i18n);
app.use(
    createGtm({
        id: getGTMId(new KrAuth()), // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
        /*queryParams: {
            // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
            gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
            gtm_preview: 'env-4',
            gtm_cookies_win: 'x',
        },*/
        //source: 'https://www.googletagmanager.com/gtm.js', // Add your own serverside GTM script
        defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
        compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
        nonce: '2726c7f26c', // Will add `nonce` to the script tag
        enabled: false, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: true, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
        //ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
        trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    })
);
router.isReady().then(() => {
    app.mount('#app');
});
