<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <div class="form-div">
            <kr-panel>
                <template #title>
                    <div class="col-xs-12 col-sm-6">
                        <h1>Send Invites Settings</h1>
                    </div>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <invite-form :form="form" mode="create" :course="course" :new_students="new_students" :all_students="all_students" />
                    </div>
                    <div class="col-xs-12 col-md-6">
                        <div class="buttonGroup">
                            <button class="btn btn-success" :disabled="!canSend ? true : undefined" aria-label="Send Invites" @click="submit">
                                <i class="fa fa-paper-plane marginRight8" aria-hidden="true" />Send Invites
                            </button>
                            <!--<button class="btn btn-default " :disabled="(!canSend) ? true:undefined" @click="openEmailSampleModal" aria-label="Preview Email">
				            	<i class="fa fa-eye"></i>&nbsp;Preview Email
				            </button>-->
                            <router-link
                                v-slot="{ navigate }"
                                :to="{
                                    name: 'courses.view',
                                    params: { id: $route.params.course_id },
                                    query: { tab: 'student' },
                                }"
                                custom
                            >
                                <button class="btn btn-default" @click="navigate"><i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back</button>
                            </router-link>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>
        <div id="emailSampleModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="emailSampleModal-title">
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="emailSampleModal-title" class="modal-title">Preview Email</h2>
                    </div>
                    <div class="modal-body">
                        <div class="emailer">
                            <div class="emailerHeader">
                                <img
                                    height="auto"
                                    src="https://cdn.intedashboard.com/emails/logo/white_intedashboard.png"
                                    width="270"
                                    alt="InteDashboard | TBL Makes Teams Work"
                                    role="presentation"
                                />
                            </div>
                            <div class="emailerContent">
                                <div v-html="form.model.title" />
                                <div v-html="form.model.emailContent" />
                                <button
                                    v-tooltip="{
                                        content: 'Unable to click. For preview purposes only.',
                                    }"
                                    class="btn btn-primary"
                                >
                                    COMPLETE NOW
                                </button>
                            </div>
                            <div class="emailerSubContent">
                                <div>
                                    <div class="col-xs-12 col-md-6">
                                        <p class="marginBottom10">Help Center</p>
                                        <a class="fontBold link" href="mailto:support@intedashboard.com">support@intedashboard.com</a>
                                    </div>
                                </div>
                                <div>
                                    <p class="marginTop10 fontBold">Social Media</p>
                                    <div class="socialMed marginTop10">
                                        <div class="col-xs-1">
                                            <a href="https://www.linkedin.com/company/cognalearn/" target="_blank">
                                                <img
                                                    height="12"
                                                    src="https://cdn.intedashboard.com/emails/facebook.png"
                                                    alt="Visit InteDashboard on Facebook"
                                                    role="presentation"
                                                />
                                            </a>
                                        </div>
                                        <div class="col-xs-1">
                                            <a href="https://twitter.com/InteDashboard" target="_blank">
                                                <img
                                                    width="12"
                                                    src="https://cdn.intedashboard.com/emails/twitter.png"
                                                    alt="Follow InteDashboard on Twitter"
                                                    role="presentation"
                                                />
                                            </a>
                                        </div>
                                        <div class="col-xs-1">
                                            <a href="https://www.linkedin.com/company/cognalearn/" target="_blank">
                                                <img
                                                    height="12"
                                                    src="https://cdn.intedashboard.com/emails/linkedin.png"
                                                    alt="Connect with InteDashboard on LinkedIn"
                                                    role="presentation"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="emailerFooter flexSpaceBetween">
                                <div>Copyright © CognaLearn Pte Ltd 2019</div>
                                <div class="fontBold flexOnly">
                                    <div>
                                        <a href="">Terms & Condition</a>
                                    </div>
                                    <div class="marginLeft20">
                                        <a href="">Privacy Policy</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            auth: auth,
            form: new KrForm({
                fields: {
                    courseUuid: 'default!:' + this.$route.params.course_id,
                    recipients: 'type!:array',
                    title: '',
                    emailContent: '',
                    emailContentIsHTML: '',
                    isInviteNotScheduled: '',
                    scheduledAt: '',
                },
                urls: {
                    post: '/students/send-invitation-setting',
                },
            }),
            course: {},
            all_students: [],
            new_students: [],
        };
    },
    components: {
        'invite-form': require(`./form-partials/partial.invite.vue`).default,
    },
    computed: {
        canSend() {
            if (
                this.form.model.recipients.length != 0 &&
                this.form.model.title &&
                this.form.model.emailContent &&
                (this.form.model.isInviteNotScheduled || (!this.form.model.isInviteNotScheduled && this.form.model.scheduledAt))
            ) {
                return true;
            }
            return false;
        },
    },
    created() {
        var that = this;
        Events.fire('topbar_update', {
            title: '<i class="fas fa-graduation-cap"></i>&nbsp;Courses',
        });

        this.form.model.emailContentIsHTML = true;
        this.form.model.title = 'Activate your InteDashboard student account';
        this.form.model.emailContent = `<h1 style="margin-bottom:0;color:#718096;">Welcome to InteDashboard!</h1> <br><p style="color:#718096;">You can now activate your account.</p>`;
        this.form.model.isInviteNotScheduled = true;

        axios
            .all([
                axios.get('/courses/' + this.$route.params.course_id),
                axios.get('/courses/' + this.$route.params.course_id + '/students?display=emailSettings'),
            ])
            .then(
                axios.spread(function (courseResponse, studentsResponse) {
                    that.course = courseResponse.data.data;

                    var courseDescription = '';

                    if (that.course.description) {
                        courseDescription = `<p style="margin-bottom:0;color:#718096;"><b>Course Overview</b></p> <p style="color:#718096;">${that.course.description.replace(
                            /\n\n/g,
                            '</p><p style="color:#718096;">'
                        )}</p>`;
                    }

                    console.log(that.auth);

                    var courseCode = '';

                    if (that.course.code) {
                        courseCode = `(${that.course.code})`;
                    }

                    that.form.model.emailContent = `<h1 style="margin-bottom:0;color:#718096;">Welcome to InteDashboard!</h1> <br><p style="color:#718096;">You are now invited to <b>${that.course.name} ${courseCode}</b></p> ${courseDescription} <br><p style="color:#718096;">You can now activate your account.</p> `;

                    Events.fire('topbar_update', {
                        breadcrumb: [
                            { name: 'Home' },
                            { name: 'Courses', link: 'courses.index' },
                            {
                                name: that.course.name + (that.course.code ? ' (' + that.course.code + ')' : ''),
                                link: 'courses.view',
                                params: { id: that.$route.params.course_id },
                                query: { tab: 'student' },
                            },
                            { name: 'Send Invites Settings' },
                        ],
                    });

                    for (var i = 0; i < studentsResponse.data.all.length; i++) {
                        if (that.isEmail(studentsResponse.data.all[i].email)) {
                            studentsResponse.data.all[i].displayName += ` (${studentsResponse.data.all[i].email})`;
                            that.all_students.push(studentsResponse.data.all[i]);
                        }
                    }
                    //that.all_students =studentsResponse.data.all;
                    for (var i = 0; i < studentsResponse.data.new.length; i++) {
                        //console.log(studentsResponse.data.new[i])
                        if (that.isEmail(studentsResponse.data.new[i].email)) {
                            studentsResponse.data.new[i].displayName += ` (${studentsResponse.data.new[i].email})`;
                            that.new_students.push(studentsResponse.data.new[i]);
                        }
                    }
                    //that.new_students =studentsResponse.data.new;
                    that.component_done_loading = true;
                })
            );
    },
    methods: {
        isEmail(email) {
            var re =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        openEmailSampleModal() {
            $('#emailSampleModal').modal('show');
        },
        submit() {
            var that = this;
            this.form.post().then(function (response) {
                if (!response.krFormError) {
                    that.$router.push({
                        name: 'courses.view',
                        params: { id: that.$route.params.course_id },
                        query: { tab: 'student' },
                    });
                }
            });
        },
    },
};
</script>
<style scoped>
.modal {
    text-align: center;
    padding: 0 !important;
}

.modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.preview.modal-content {
    border: 3px solid #fab762;
}

.preview .modal-body {
    padding: 0;
}

.emailerHeader {
    padding: 30px;
    text-align: center;
    background-color: #495060;
}

.emailerContent {
    padding: 30px;
    text-align: center;
}

.emailerContent p {
    margin: 0;
}

.emailerContent div {
    margin: 20px;
}

.emailerContent div:first-child {
    font-weight: bold;
    font-size: 20px;
    margin: 0;
}

.emailerContent button {
    padding: 10px;
    width: 270px;
}

.emailerContent .btn {
    margin-left: 0;
}

.emailerSubContent {
    padding: 30px;
    background-color: #f8f8f8;
    text-align: center;
    font-size: 12px;
}

.emailerSubContent > div:first-child p {
    font-weight: bold;
}

.lineBreak {
    background: #ebebeb;
    height: 2px;
    margin: 20px 0;
}

.socialMed {
    display: flex;
    justify-content: center;
}

.emailerFooter {
    font-size: 12px;
    text-align: center;
    color: #fff;
    background-color: #495060;
    padding: 10px;
}

.emailerFooter a,
.emailerFooter a:hover,
.emailerFooter a:focus,
.emailerFooter a:active {
    color: #fff;
}

.emailer button {
    cursor: not-allowed;
}

@media (min-width: 992px) {
    .emailerSubContent > div:first-child,
    .emailerSubContent span {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .emailerContent button {
        width: 270px;
    }
}

@media (max-width: 991px) {
    .emailerSubContent span {
        margin: 10px 0;
    }
}
</style>
