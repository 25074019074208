<template>
    <div>
        <div ref="infoBox" class="col-xs-12 right-container course-table-responsive">
            <div class="pageTitle">
                <h1>Courses</h1>
                <p class="col-md-6">
                    This is the Course Listing page that contains all courses that you have created and collaborate with. You can filter the list between
                    "Active" or "
                    <a href="https://help.intedashboard.com/how-do-i-archive-a-course" target="newTab">
                        <i class="fas fa-info-circle" aria-hidden="true" />
                        Archived Course
                    </a>
                    " from the dropdown on the top-right corner of the page.
                    <a href="https://help.intedashboard.com/how-do-i-create-a-course" target="newTab">
                        <i class="fas fa-info-circle" aria-hidden="true" />
                        Create and manage courses.
                    </a>
                </p>
            </div>

            <kr-search
                name="courses_listing"
                :url="{ url: '/courses' }"
                :columns="{
                    name: 'display:Name|sortable:true|width:calc(100% - 142px - 142px - 142px - 142px - 165px - 148px)',
                    code: 'display:Code|sortable:true|mobile:hide|width:142px',
                    startsFrom: 'display:Starts Form|sortable:true|src:startDate|width:142px|mobile:hide',
                    endsOn: 'display:Ends On |sortable:true|src:endDate|width:142px|mobile:hide',
                    owner: 'display:Owner|sortable:false|mobile:hide|width:142px',
                    myRole: 'display:My Role|sortable:false|mobile:hide|width:170px',
                    action: 'display:Actions|sortable:false|width:148px',
                }"
                :options="{
                    archived_field: true,
                    drop_page_number: false,
                    action_column: false,
                    sort: 'startDate',
                    order: 'desc',
                    pageName: 'Courses',
                }"
                @retrieve-data="getSearchData"
            >
                <template #upper-right>
                    <router-link v-slot="{ navigate }" :to="{ name: 'courses.create' }" custom>
                        <button class="btn btn-success" :disabled="auth.isImpersonation() ? true : undefined" @click="navigate">
                            <i class="fa fa-plus marginRight8" aria-label="Add" /> New Course
                        </button>
                    </router-link>
                </template>
                <template #table-title="props">
                    <div>
                        <template v-if="props.model.meta.paginator.total > 0">
                            Showing {{ props.model.from }} - {{ props.model.to }} of
                            {{ props.model.meta.paginator.total }}
                        </template>
                    </div>
                </template>

                <template #bottom-display-from-to="props">
                    <div v-if="props.model.meta.paginator.total > 0" class="displayNo">
                        Showing {{ props.model.from }} - {{ props.model.to }} of
                        {{ props.model.meta.paginator.total }}
                    </div>
                </template>

                <template #ndata>
                    <tr>
                        <td class="borderNone">
                            No courses yet,
                            <router-link
                                v-if="!auth.isImpersonation()"
                                :to="{ name: 'courses.create' }"
                                tag="a"
                                class="link"
                                aria-labelledby="Create a course"
                                aria-label="Click here to create one"
                            >
                                click here
                            </router-link>
                            to create one.
                        </td>
                    </tr>
                </template>
                <template #cdata="props">
                    <td>
                        <router-link
                            v-tooltip="{ content: 'View modules and activities page' }"
                            tag="a"
                            :to="{
                                name: 'courses.view',
                                params: { id: props.model.course.uuid },
                            }"
                            class="link"
                            :aria-label="props.model.course.name + ' click to view modules and activities page'"
                            v-html="highlight(props.model.course.name)"
                        />
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <router-link
                            v-if="props.model.course.code"
                            v-tooltip="{ content: 'View modules and activities page' }"
                            tag="a"
                            :to="{
                                name: 'courses.view',
                                params: { id: props.model.course.uuid },
                            }"
                            class="link"
                            :aria-label="'course code ' + props.model.course.code"
                            v-html="highlight(props.model.course.code)"
                        />
                    </td>
                    <td class="hidden-xs hidden-sm">
                        {{ convertToReadableDate(props.model.course.startDate, 'DD MMM YYYY').date }}
                    </td>
                    <td class="hidden-xs hidden-sm">
                        {{ convertToReadableDate(props.model.course.endDate, 'DD MMM YYYY').date }}
                    </td>
                    <td
                        v-if="props.model.course.owner"
                        class="hidden-xs hidden-sm whiteSpaceNowrap"
                        v-html="highlight(props.model.course.owner.length > 30 ? truncate(props.model.course.owner, 28) : props.model.course.owner)"
                    />
                    <td v-else class="hidden-xs hidden-sm whiteSpaceNowrap" :aria-label="'owner'" />
                    <td class="hidden-xs hidden-sm">
                        <template v-if="getPrivilege(props.model) == 'Owner'">
                            <i class="fa fa-circle ownerCircle marginRight8" aria-hidden="true" />Course Owner
                        </template>
                        <template v-if="getPrivilege(props.model) == 'Full Access'">
                            <i class="fa fa-circle fullAccessCircle marginRight8" aria-hidden="true" />Full Access
                        </template>
                        <template v-if="getPrivilege(props.model) == 'Read Only'">
                            <i class="fa fa-circle readOnlyCircle marginRight8" aria-hidden="true" />Read Only
                        </template>
                    </td>
                    <td class="py-9px">
                        <div class="dropdown">
                            <button
                                id="viewDropdownMenuButton"
                                class="btn drop-down"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                aria-label="Actions"
                                @click="dropdownToggle"
                            >
                                Actions<i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                            </button>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                <ul>
                                    <li>
                                        <router-link
                                            v-tooltip="{
                                                content: hasWritePrivilege(props.model) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="primary-state"
                                            :to="{
                                                name: 'courses.view',
                                                params: {
                                                    id: props.model.course.uuid,
                                                },
                                            }"
                                            :aria-labelledby="'View ' + props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '')"
                                        >
                                            <span> <i class="fas fa-eye marginRight8" aria-hidden="true" />View Course </span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <a
                                            v-tooltip="{
                                                content: hasWritePrivilege(props.model) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="primary-state"
                                            :class="{
                                                disabled: hasWritePrivilege(props.model) == -1,
                                            }"
                                            href="#"
                                            :aria-labelledby="
                                                'Download ' + props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '') + 'Report'
                                            "
                                            aria-label="Generate Course Grades Report"
                                            @click.prevent="hasWritePrivilege(props.model) == -1 ? '' : openEmailGradesConfirmationModal(props.model)"
                                        >
                                            <span> <i class="fa fa-download marginRight8" aria-hidden="true" />Export Grades </span>
                                        </a>
                                    </li>

                                    <li>
                                        <a
                                            v-tooltip="{
                                                content: hasWritePrivilege(props.model) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="primary-state"
                                            :class="{
                                                disabled: hasWritePrivilege(props.model) == -1,
                                            }"
                                            href="#"
                                            :aria-labelledby="
                                                'Make a copy of ' + props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '')
                                            "
                                            @click.prevent="hasWritePrivilege(props.model) == -1 ? '' : openCopyCourseConfirmationModal(props.model)"
                                        >
                                            <span> <i class="fa fa-files-o marginRight8" aria-hidden="true" />Make A Copy </span>
                                        </a>
                                    </li>

                                    <hr />

                                    <li v-if="searchData.query.isArchived === '0'">
                                        <a
                                            v-tooltip="{
                                                content: !hasWritePrivilege(props.model) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="danger-state"
                                            :class="{
                                                disabled: !hasWritePrivilege(props.model) == -1,
                                            }"
                                            href="#"
                                            aria-label="Archive"
                                            @click.prevent="hasWritePrivilege(props.model) == -1 ? '' : openSuspendModal(props.model)"
                                        >
                                            <span> <i class="fa fa-archive marginRight8" aria-hidden="true" />Archive </span>
                                        </a>
                                    </li>

                                    <li v-if="searchData.query.isArchived === '1'">
                                        <a
                                            v-tooltip="{
                                                content: hasWritePrivilege(props.model) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="primary-state"
                                            :class="{
                                                disabled: hasWritePrivilege(props.model) == -1,
                                            }"
                                            href="#"
                                            aria-label="Reinstate"
                                            @click.prevent="hasWritePrivilege(props.model) == -1 ? '' : openSuspendModal(props.model)"
                                        >
                                            <span> <i class="fa fa-refresh marginRight8" aria-hidden="true" />Reinstate </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </template>
            </kr-search>
        </div>

        <div class="modal duplicateCourseModal primary-modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="duplicateCourseModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="duplicateCourseModal-title" class="modal-title">Make A Copy Confirmation</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <h3>Course Name</h3>
                        <h4>
                            {{ course.name }}
                            <template v-if="course.code"> ({{ course.code }}) </template>
                        </h4>

                        <p class="marginTop20">Are you sure you want to</p>
                        <p>make a copy of this course?</p>

                        <fieldset class="textAlignLeft newRadioField">
                            <legend class="margin0">Make a copy of</legend>
                            <div class="checkboxField">
                                <input
                                    id="copyModules"
                                    v-model="copyCourseForm.copyModules"
                                    type="checkbox"
                                    name="makeACopyOf"
                                    :aria-checked="copyCourseForm.copyModules"
                                    @change="toggleCourseDuplicate"
                                />
                                <label for="copyModules"> Copy modules to the new course </label>
                            </div>
                            <div class="checkboxField">
                                <input
                                    id="copyActivities"
                                    v-model="copyCourseForm.copyActivities"
                                    v-tooltip="!copyCourseForm.copyModules ? 'Copy of Modules are not check' : ''"
                                    :class="{
                                        'disabled hasTooltip': !copyCourseForm.copyModules,
                                    }"
                                    type="checkbox"
                                    name="makeACopyOf"
                                    :aria-checked="copyCourseForm.copyActivities"
                                    @change="toggleCourseDuplicate"
                                />
                                <label for="copyActivities"> Copy activities to the new course </label>
                            </div>
                            <div class="checkboxField">
                                <input
                                    id="copyActivityQuestions"
                                    v-model="copyCourseForm.copyActivityQuestions"
                                    v-tooltip="!copyCourseForm.copyActivities ? 'Copy of Activities are not check' : ''"
                                    :class="{
                                        'disabled hasTooltip': !copyCourseForm.copyActivities,
                                    }"
                                    type="checkbox"
                                    name="makeACopyOf"
                                    :aria-checked="copyCourseForm.copyActivityQuestions"
                                    @change="toggleCourseDuplicate"
                                />
                                <label for="copyActivityQuestions"> Copy activities to the new course </label>
                            </div>
                        </fieldset>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Cancel</button>
                        <button class="btn btn-success" data-dismiss="modal" @click.prevent="copyCourse()">Yes, Copy Course</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="emailGradesConfirmationModal"
            class="modal default-modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="emailGradesConfirmationModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="emailGradesConfirmationModal-title" class="modal-title">Generate Course Grades Report</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <p>You will be notified when your</p>
                        <p>Grades Report is ready (approx. 2-3 minutes).</p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Cancel</button>
                        <button class="btn btn-success" data-dismiss="modal" @click.prevent="emailGrades()">Yes, Proceed</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="suspendModal"
            class="modal"
            :class="{ 'danger-modal': searchData.query.isArchived == '0', 'warning-modal': searchData.query.isArchived != '0' }"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="suspendModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="suspendModal-title" class="modal-title">
                            <template v-if="searchData.query.isArchived == '0'"> Archive </template>
                            <template v-else> Reinstate </template>
                        </h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                        <h2 class="marginTop40">
                            <template v-if="searchData.query.isArchived == '0'"> Archive </template>
                            <template v-else> Reinstate </template>
                        </h2>

                        <h3 class="marginBottom20 fw-normal capitalize">
                            {{ course.name }}
                        </h3>

                        <template v-if="searchData.query.isArchived == '0'">
                            <p class="marginTop20">Archiving this course will hide it</p>

                            <p>from your active courses list.</p>

                            <p class="marginTop20">
                                <i>You can reinstate it later by filtering</i>
                            </p>

                            <p>
                                <i>your courses and showing Archived Courses.</i>
                            </p>

                            <p class="marginTop20">
                                <b>Are you sure to archive the course?</b>
                            </p>
                        </template>

                        <template v-else>
                            <p class="marginTop20">Reinstating this course will visible it</p>

                            <p>in your active courses list</p>

                            <p class="marginTop20">
                                <i>You can archive it later if needed by </i>
                            </p>

                            <p>
                                <i>filtering your courses and showing Active Courses.</i>
                            </p>

                            <p class="marginTop20">
                                <b>Are you sure to reinstate the course?</b>
                            </p>
                        </template>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                        <button v-if="searchData.query.isArchived == '0'" class="btn btn-danger" data-dismiss="modal" @click.prevent="archive()">
                            <i class="fa fa-archive marginRight8" aria-hidden="true" />Yes, Archive
                        </button>
                        <button v-else class="btn btn-success" data-dismiss="modal" @click.prevent="reinstate()">Yes, Reinstate</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import KrAuth from '../../../components/auth/auth';
import KrForm from '../../../components/forms/form';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            isBusy: false,
            searchData: {
                meta: { paginator: { total: 0 }, filters: {} },
                query: { isTrashed: '0', isArchived: '0' },
            },
            course: {},
            copyCourseForm: {
                copyModules: true,
                copyActivities: true,
                copyActivityQuestions: true,
            },
            readOnlyLabel: 'You have read-only access',
            rightContainerHeight: null,
        };
    },
    created() {
        var that = this;
        Events.fire('topbar_update', {
            breadcrumb: [{ name: 'Home' }, { name: 'Courses', link: 'courses.index' }],
        });
    },
    methods: {
        openSuspendModal(course) {
            this.course = course.course;
            $('#suspendModal').modal('show');
        },
        archive() {
            $('#suspendModal').modal('hide');
            var success = function () {
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'You have sucessfully removed course from list',
                });
                Events.fire('courses_listing_refresh');
            }.bind(this);
            var del = function () {
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .patch('/courses/' + this.course.uuid)
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        reinstate() {
            $('#suspendModal').modal('hide');
            var success = function () {
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Course has been reinstated',
                });
                Events.fire('courses_listing_refresh');
            }.bind(this);
            var del = function () {
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .patch('/courses/' + this.course.uuid)
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        toggleCourseDuplicate() {
            if (this.copyCourseForm.copyModules == false) {
                this.copyCourseForm.copyActivities = false;
                this.copyCourseForm.copyActivityQuestions = false;
            }
            if (this.copyCourseForm.copyActivities == false) {
                this.copyCourseForm.copyActivityQuestions = false;
            }
        },
        openEmailGradesConfirmationModal(course) {
            this.course = course.course;
            $('#emailGradesConfirmationModal').modal('show');
        },
        emailGrades() {
            var that = this;
            axios({
                url: '/courses/' + this.course.uuid + '/grades',
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                that.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'We will generate the Grades Report for this course and notify you once finished',
                });
            });
        },
        openCopyCourseConfirmationModal(course) {
            this.course = course.course;
            this.copyCourseForm = {
                copyModules: true,
                copyActivities: true,
                copyActivityQuestions: true,
            };
            $('.duplicateCourseModal').modal('show');
        },
        copyCourse() {
            var that = this;
            axios
                .post('courses/' + that.course.uuid + '/duplicate', this.copyCourseForm)
                .then(function (response) {
                    that.$router.push({
                        name: 'courses.view',
                        params: { id: response.data.data.uuid },
                    });
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Course has been successfully copied',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to copy course at this moment, please try again later',
                    });
                });
        },
        getSearchData(data) {
            this.searchData = data;
        },
        highlight(text) {
            if (this.searchData.query.q != '' && text != null) {
                return text.replace(new RegExp(this.searchData.query.q, 'gi'), '<span class="table-keyword-highlight">$&</span>');
            } else {
                return text;
            }
        },
        dropdownToggle() {
            if ($('table tbody tr').length >= 6) {
                $('table tbody tr:nth-last-child(-n+4) td:last-child div').addClass('dropup');
            } else if ($('table tbody tr').length == 5) {
                $('table tbody tr:nth-last-child(-n+2) td:last-child div').addClass('dropup');
            }
        },
        truncate(str, characters = 100) {
            return _.truncate(str, {
                length: characters,
                omission: '',
            });
        },
    },
};
</script>
