<template>
    <div>
        <kr-panel :with-footer="false">
            <template #title>
                <div class="col-xs-12 flex flexSpaceBetween align-items">
                    <div class="col-xs-12 col-sm-6">
                        <h2>
                            <template v-if="mode == 'view'"> Course Info </template>
                            <template v-else> Edit Course </template>
                        </h2>
                    </div>
                    <div class="flexRight textAlignRight">
                        <template v-if="mode == 'view'">
                            <button
                                v-if="hasWritePrivilege(course)"
                                v-tooltip="{
                                    content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                }"
                                class="btn btn-default primaryHover"
                                :class="{ 'isDisabled hasTooltip': hasWritePrivilege(course) == -1 }"
                                aria-label="Edit Course"
                                @click.prevent="mode = 'edit'"
                            >
                                <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                            </button>
                        </template>
                        <template v-else>
                            <button class="btn btn-default" exact @click.prevent="mode = 'view'">Cancel</button>
                            <button class="btn btn-success" @click.prevent="submitForm">Update</button>
                        </template>
                        <div
                            v-if="mode == 'view'"
                            v-tooltip="{
                                content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                            }"
                            class="viewDropdown dropdown marginLeft20"
                        >
                            <button
                                id="viewDropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                aria-label="More Actions"
                            >
                                More Actions
                                <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                            </button>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                <ul>
                                    <li>
                                        <a
                                            v-tooltip="{
                                                content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="primary-state"
                                            :class="{
                                                'isDisabled hasTooltip': hasWritePrivilege(course) == -1,
                                            }"
                                            href="#"
                                            @click.prevent="openCopyCourseConfirmationModal()"
                                        >
                                            <span> <i class="fa fa-files-o marginRight8" aria-hidden="true" />Make A Copy </span>
                                        </a>
                                    </li>

                                    <hr />

                                    <li v-if="!course.isArchived">
                                        <a class="danger-state" href="#" @click.prevent="openSuspendModal()">
                                            <span> <i class="fa fa-archive marginRight8" aria-hidden="true" />Archive Course </span>
                                        </a>
                                    </li>

                                    <li v-else>
                                        <a class="primary-state" href="#" @click.prevent="openSuspendModal()">
                                            <span> <i class="fa fa-refresh marginRight8" aria-hidden="true" />Reinstate </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #content>
                <div v-if="mode == 'view'" class="col-xs-12 col-md-6 viewFullDetails">
                    <div class="col-xs-12 flex">
                        <div class="col-xs-12 col-md-6">
                            <label class="control-label"> Course Name </label>
                            <p class="form-control-static">
                                {{ course.name }}
                            </p>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <label class="control-label"> Course Code </label>
                            <p class="form-control-static">
                                {{ course.code ? course.code : '-' }}
                            </p>
                        </div>
                    </div>
                    <div class="col-xs-12 flex">
                        <div class="col-xs-12 col-md-6">
                            <label class="control-label"> Course Starts From </label>
                            <p class="form-control-static">
                                <i class="fa fa-calendar marginRight8" aria-hidden="true" />{{
                                    convertToReadableDate(course.startDate, 'DD MMM YYYY').date
                                }}
                                ({{ convertToReadableDate(course.startDate, 'DD MMM YYYY').current_timezone }})
                            </p>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <label class="control-label"> Course Ends On </label>
                            <p class="form-control-static">
                                <i class="fa fa-calendar marginRight8" aria-hidden="true" />{{ convertToReadableDate(course.endDate, 'DD MMM YYYY').date }} ({{
                                    convertToReadableDate(course.endDate, 'DD MMM YYYY').current_timezone
                                }})
                            </p>
                        </div>
                    </div>
                    <div class="col-xs-12 flex">
                        <div class="col-xs-12 col-md-6">
                            <label class="control-label"> Created On </label>
                            <p class="form-control-static">
                                <i class="fa fa-calendar marginRight8" aria-hidden="true" />{{
                                    convertToReadableDate(course.dateCreated, 'DD MMM YYYY, h:mm a').date
                                }}
                                ({{ convertToReadableDate(course.dateCreated, 'DD MMM YYYY, h:mm a').current_timezone }})
                            </p>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <label class="control-label"> Created By </label>
                            <p class="form-control-static"><i class="fa fa-user-o marginRight8" aria-hidden="true" />{{ course.createBy }}</p>
                        </div>
                    </div>
                    <div class="col-xs-12 flex marginBottom20">
                        <div class="col-xs-12">
                            <label class="control-label"> Course Overview </label>
                            <div v-if="course.description" class="whiteSpacePreLine" v-html="course.description" />
                            <div v-else>-</div>
                        </div>
                    </div>
                    <div class="col-xs-12 flex">
                        <div class="col-xs-12">
                            <label class="control-label"> Course Thumbnail </label>
                            <div v-if="course.coverImage">
                                <div class="actual-image marginTop20" :style="'background-image:url(' + course.coverImage + ');'" />
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                </div>
                <div v-else class="col-xs-12 col-md-6">
                    <course-form :form="form" />

                    <div class="marginTop20 gap10" :class="{ buttonGroup: !isBelow768px, flex: isBelow768px }">
                        <button class="btn btn-default margin0" exact @click.prevent="mode = 'view'">Cancel</button>
                        <button class="btn btn-success" @click.prevent="submitForm">Update</button>
                    </div>
                </div>
            </template>
        </kr-panel>

        <div id="copyCourseModal" class="modal primary-modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="copyCourseModal-title">
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="copyCourseModal-title" class="modal-title">Make A Copy</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <h3>Course Name</h3>
                        <h4>
                            {{ course.name }}
                            <template v-if="course.code"> ({{ course.code }}) </template>
                        </h4>

                        <p class="marginTop20">Are you sure you want to</p>
                        <p>make a copy of this course?</p>

                        <fieldset class="textAlignLeft newRadioField">
                            <legend class="margin0">Make a copy of</legend>
                            <div class="checkboxField">
                                <input
                                    v-model="copyCourseForm.copyModules"
                                    type="checkbox"
                                    name="makeACopyOf"
                                    id="copyModules"
                                    :aria-checked="copyCourseForm.copyModules"
                                    @change="toggleCourseDuplicate"
                                />
                                <label for="copyModules"> Copy modules to the new course </label>
                            </div>
                            <div class="checkboxField">
                                <input
                                    v-tooltip="!copyCourseForm.copyModules ? 'Copy of Modules are not check' : ''"
                                    :class="{
                                        'disabled hasTooltip': !copyCourseForm.copyModules,
                                    }"
                                    v-model="copyCourseForm.copyActivities"
                                    type="checkbox"
                                    name="makeACopyOf"
                                    id="copyActivities"
                                    :aria-checked="copyCourseForm.copyActivities"
                                    @change="toggleCourseDuplicate"
                                />
                                <label for="copyActivities"> Copy activities to the new course </label>
                            </div>
                            <div class="checkboxField">
                                <input
                                    v-tooltip="!copyCourseForm.copyActivities ? 'Copy of Activities are not check' : ''"
                                    :class="{
                                        'disabled hasTooltip': !copyCourseForm.copyActivities,
                                    }"
                                    v-model="copyCourseForm.copyActivityQuestions"
                                    type="checkbox"
                                    name="makeACopyOf"
                                    id="copyActivityQuestions"
                                    :aria-checked="copyCourseForm.copyActivityQuestions"
                                    @change="toggleCourseDuplicate"
                                />
                                <label for="copyActivityQuestions"> Copy activities to the new course </label>
                            </div>
                        </fieldset>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Cancel</button>
                        <button class="btn btn-success" data-dismiss="modal" @click.prevent="copyCourse()">Yes, Copy Course</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="courseSuspendModal"
            class="modal"
            :class="{ 'danger-modal': !course.isArchived, 'warning-modal': course.isArchived }"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="courseSuspendModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="courseSuspendModal-title" class="modal-title">
                            <template v-if="!course.isArchived"> Archive </template>
                            <template v-else> Reinstate </template>
                        </h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                        <h2 class="marginTop40">
                            <template v-if="!course.isArchived"> Archive </template>
                            <template v-else> Reinstate </template>
                        </h2>

                        <h3 class="marginBottom20 fw-normal capitalize">
                            {{ course.name }}
                        </h3>

                        <template v-if="!course.isArchived">
                            <p class="marginTop20">Archiving this course will hide it</p>

                            <p>from your active courses list.</p>

                            <p class="marginTop20">
                                <i>You can reinstate it later by filtering</i>
                            </p>

                            <p>
                                <i>your courses and showing Archived Courses.</i>
                            </p>

                            <p class="marginTop20">
                                <b>Are you sure to archive the course?</b>
                            </p>
                        </template>

                        <template v-else>
                            <p class="marginTop20">Reinstating this course will visible it</p>

                            <p>in your active courses list</p>

                            <p class="marginTop20">
                                <i>You can archive it later if needed by </i>
                            </p>

                            <p>
                                <i>filtering your courses and showing Active Courses.</i>
                            </p>

                            <p class="marginTop20">
                                <b>Are you sure to reinstate the course?</b>
                            </p>
                        </template>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                        <button v-if="!course.isArchived" class="btn btn-danger" data-dismiss="modal" @click.prevent="archive()">
                            <i class="fa fa-archive marginRight8" aria-hidden="true" />Yes, Archive
                        </button>
                        <button v-else class="btn btn-success" data-dismiss="modal" @click.prevent="reinstate()">Yes, Reinstate</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    components: {
        'course-form': require(`./partial.course.vue`).default,
    },
    props: {
        course: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            auth: new KrAuth(),
            mode: 'view',
            copyCourseForm: {
                copyModules: true,
                copyActivities: true,
                copyActivityQuestions: true,
            },
            form: new KrForm({
                fields: {
                    name: '',
                    code: '',
                    description: '',
                    descriptionIsHTML: '',
                    startDate: '',
                    endDate: '',
                    objectives: 'type!:array',
                    coverImage: '',
                },
                props: {},
                urls: {
                    put: 'courses/' + this.$route.params.id,
                },
            }),
        };
    },
    computed: {
        canSend() {
            if (this.form.model.name && this.form.model.startDate && this.form.model.endDate) {
                return true;
            }
            return false;
        },
    },
    created() {
        this.form.setModel(this.course);
        if (!this.form.model.objectives) {
            this.form.model.objectives = [];
        }
        this.form.model.descriptionIsHTML = false;
    },
    methods: {
        submitForm() {
            var that = this;
            this.form.put().then(function (response) {
                if (!response.krFormError) {
                    that.mode = 'view';
                    that.$emit('course-updated', response.data.data);
                }
            });
        },
        archive() {
            $('#courseSuspendModal').modal('hide');
            var success = function () {
                this.course.isArchived = true;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'You have sucessfully removed course from list',
                });
                this.$router.push({ name: 'courses.index' });
            }.bind(this);
            var del = function () {
                this.course.isArchived = false;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .patch('/courses/' + this.course.uuid)
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        reinstate() {
            $('#courseSuspendModal').modal('hide');
            var success = function () {
                var that = this;
                this.course.isArchived = false;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Course has been reinstated',
                });
            }.bind(this);
            var del = function () {
                this.course.isArchived = true;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .patch('/courses/' + this.course.uuid)
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        openSuspendModal() {
            $('#courseSuspendModal').modal('show');
        },
        toggleCourseDuplicate() {
            if (this.copyCourseForm.copyModules == false) {
                this.copyCourseForm.copyActivities = false;
                this.copyCourseForm.copyActivityQuestions = false;
            }
            if (this.copyCourseForm.copyActivities == false) {
                this.copyCourseForm.copyActivityQuestions = false;
            }
        },
        openCopyCourseConfirmationModal() {
            this.selectedCourse = false;
            this.copyCourseForm = {
                copyModules: true,
                copyActivities: true,
                copyActivityQuestions: true,
            };
            $('#copyCourseModal').modal('show');
        },
        copyCourse() {
            var that = this;
            axios
                .post('courses/' + that.course.uuid + '/duplicate', this.copyCourseForm)
                .then(function (response) {
                    that.$router.push({
                        name: 'courses.view',
                        params: { id: response.data.data.uuid },
                    });
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Course has been successfully copied',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to copy course at this moment, please try again later',
                    });
                });
        },
    },
};
</script>

<style scoped>
#copyCourseModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#copyCourseModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}
#copyModuleModal .modal-body .row:last-child {
    margin-top: 30px;
}

#copyModuleModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#copyModuleModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}
</style>
