<template>
    <footer class="no-print">
        <div class="hidden-max-436">
            Copyright &copy CognaLearn Pte Ltd 2015 ~
            <span id="year">{{ year }}</span> (build: {{ BUILD_DATE }}) &nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="https://www.intedashboard.com/terms-of-use/" target="_blank" aria-label="Terms of Use opens in a new tab."> Terms of Use </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="https://www.intedashboard.com/privacy-policy/" target="_blank" aria-label="Privacy Policy opens in a new tab."> Privacy Policy </a>
            <!-- <a
                v-if="
                    auth.user().roleId == 2 &&
                    ['c79e896b-cd8e-46a5-acd6-626d454063a3', '7a690287-ecb9-4b52-8ee3-3b1d340115b5', 'c5a47ef7-eba8-4d7c-805c-3b6cc6abb53c'].includes(
                        auth.user().account.uuid
                    )
                "
                class="btn btn-default"
                :href="`https://v3.intedashboard.com/launchexternal?url=https://modernreportcard.retool.com/embedded/public/917e77cc-2b71-4f40-afd4-aadd5bb1b10a&name=${
                    auth.user().displayName
                }`"
                target="_blank"
                >Click Me</a
            > -->
        </div>

        <div class="hidden-min-436">
            <div>
                Copyright &copy CognaLearn Pte Ltd 2015 ~
                <span id="year">{{ year }}</span>
                (build: {{ BUILD_DATE }})
            </div>
            <div>
                <a href="https://www.intedashboard.com/terms-of-use/" target="_blank" aria-label="Terms of Use opens in a new tab."> Terms of Use </a>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <a href="https://www.intedashboard.com/privacy-policy/" target="_blank" aria-label="Privacy Policy opens in a new tab."> Privacy Policy </a>
                <!-- <a
                    v-if="
                        auth.user().roleId == 2 &&
                        ['c79e896b-cd8e-46a5-acd6-626d454063a3', '7a690287-ecb9-4b52-8ee3-3b1d340115b5', 'c5a47ef7-eba8-4d7c-805c-3b6cc6abb53c'].includes(
                            auth.user().account.uuid
                        )
                    "
                    class="btn btn-default"
                    :href="`https://v3.intedashboard.com/launchexternal?url=https://modernreportcard.retool.com/embedded/public/917e77cc-2b71-4f40-afd4-aadd5bb1b10a&name=${
                        auth.user().displayName
                    }`"
                    target="_blank"
                    >Click Me</a
                > -->
            </div>
        </div>

        <div class="clearfix"></div>
    </footer>
</template>
<script>
import KrAuth from '../../../../components/auth/auth.js';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            BUILD_DATE: process.env.VUE_APP_BUILD_DATE,
            year: moment().format('YYYY'),
        };
    },
    watch: {
        $route: {
            handler: function () {
                if (_.get(this.$route.params, 'otp', false)) {
                    $('footer').hide();
                } else {
                    $('footer').show();
                }
            },
            deep: true,
        },
    },
    mounted() {
        if (_.get(this.$route.params, 'otp', false)) {
            $('footer').hide();
        }
    },
};
</script>
<style scoped>
body.test footer {
    margin-left: 0px;
}
</style>
