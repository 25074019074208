<template>
    <div v-if="component_done_loading">
        <div v-if="pageType == 'form'" class="marginBottom30">
            <button class="btn btn-success" @click.prevent="submitActivitySettings()">
                Review your Activity<i class="fas fa-caret-right marginLeft8" aria-hidden="true" />
            </button>
            <button class="btn btn-default" @click.prevent="saveAndExit"><i class="fa fa-floppy-o marginRight8" aria-hidden="true" />Save and Exit</button>
        </div>

        <div v-if="pageType != 'confirmation'" class="pageTitle">
            <h1>Optional Settings</h1>
            <p class="col-md-6">
                <template v-if="activityObj.type == 'prework'">
                    This page lets you configure behavior settings for student control, such as whether students can change download and/or print the pdf
                    documents
                </template>
                <template v-else>
                    This page lets you set up how students will answer questions in this activity. The Student App Preview panel displays how it will appear in
                    the student app. You can also add instructions in the field provided below.
                </template>
            </p>
        </div>

        <kr-panel v-if="activityObj.type == 'peerEvaluationV2'" :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <div class="col-xs-12 col-sm-6">
                    <h2>Peer Evaluation Settings</h2>
                </div>

                <div v-if="pageType == 'confirmation'" class="col-xs-12 col-sm-6 flex gap10" :class="{ justifyEnd: !isBelow768px }">
                    <template v-if="peerEvaluationV2Mode == 'edit'">
                        <button class="btn btn-success margin0 flexInherit" @click.prevent="submitActivitySettings('peerEvaluationV2')">Update</button>
                        <button class="btn btn-default margin0 flexInherit" exact @click.prevent="peerEvaluationV2Mode = 'view'">Cancel</button>
                    </template>
                    <template v-else="peerEvaluationV2Mode == 'view'">
                        <button
                            class="btn btn-default margin0 flexInherit"
                            @click.prevent="
                                peerEvaluationV2Mode = 'edit';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                        </button>
                    </template>
                </div>
            </template>
            <template #content>
                <template v-if="!isSet && peerEvaluationV2Mode == 'view' && pageType == 'confirmation'"> Not Set </template>
                <template v-else>
                    <template v-if="pageType == 'form' || (pageType == 'confirmation' && peerEvaluationV2Mode == 'edit')">
                        <div class="flex">
                            <div class="col-xs-12 col-md-6">
                                <fieldset>
                                    <legend>
                                        <h3>Behavior Settings</h3>
                                    </legend>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="allowChangeAnswersBeforeFinalSubmission"
                                            v-model="form.model.allowChangeAnswersBeforeFinalSubmission"
                                            type="checkbox"
                                            class="marginRight10"
                                            name="allowChangeAnswersBeforeFinalSubmission"
                                            :aria-checked="form.model.allowChangeAnswersBeforeFinalSubmission"
                                            @input="waitToSave()"
                                        />
                                        <label for="allowChangeAnswersBeforeFinalSubmission" class="d-block">
                                            <b>Students can change their answers</b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="allowViewQuestionsAhead"
                                            v-model="form.model.allowViewQuestionsAhead"
                                            type="checkbox"
                                            :true-value="false"
                                            :false-value="true"
                                            name="allowViewQuestionsAhead"
                                            :aria-checked="form.model.allowViewQuestionsAhead"
                                            @input="waitToSave()"
                                            @change="form.model.allowViewQuestionsAhead ? (form.model.allowViewPreviousQuestions = true) : ''"
                                        />
                                        <label for="allowViewQuestionsAhead">
                                            <b>Students must answer questions in sequence</b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginLeft30 marginBottom15 flex">
                                        <input
                                            id="allowViewPreviousQuestions"
                                            v-model="form.model.allowViewPreviousQuestions"
                                            type="checkbox"
                                            name="allowViewPreviousQuestions"
                                            :class="{ disabled: form.model.allowViewQuestionsAhead }"
                                            :aria-disabled="form.model.allowViewQuestionsAhead"
                                            :aria-checked="form.model.allowViewPreviousQuestions"
                                            @input="waitToSave()"
                                            @click="form.model.allowViewQuestionsAhead ? $event.preventDefault() : ''"
                                        />
                                        <label for="allowViewPreviousQuestions" class="d-block">
                                            <b>Students can navigate to previously answered questions</b>
                                            <br />
                                            <i v-if="form.model.allowViewQuestionsAhead" class="fs-16px">
                                                (Reason for disabling: Students must answer questions in sequence is not enabled)
                                            </i>
                                        </label>
                                    </div>
                                </fieldset>

                                <div class="marginTop15">
                                    <kr-field
                                        :form="form"
                                        display="Instructions"
                                        name="peerEvaluationV2Instruction"
                                        :options="{
                                            htmlEditor: true,
                                            placeholder: 'Enter instructions for this activity here',
                                            hasLabel: false,
                                            showError: false,
                                        }"
                                    />
                                    <kr-file-multiple
                                        class="marginTop15"
                                        :options="{ reverse: true }"
                                        :form="form"
                                        name="peerEvaluationV2Attachments"
                                        @preview-attachment="showAttachment"
                                    />
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-6">
                                <h3>Student App Preview</h3>

                                <div class="expectation-paragraph">
                                    <iframe
                                        id="peerEvaluationV2_iframe"
                                        height="600px"
                                        width="100%"
                                        :src="`${APP_URL}/in/teachers/tests/${$route.params.id}/preview-evaluation-v2`"
                                        title="Student App Preview"
                                        @load="loadIframe()"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div>
                            <h3 class="control-label">Behavior Settings</h3>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.allowChangeAnswersBeforeFinalSubmission"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Students can change their answers </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="!form.model.allowViewQuestionsAhead"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Students must answer questions in sequence </label>
                            </div>

                            <div class="marginBottom15 marginLeft30">
                                <label class="marginRight10">
                                    <template v-if="form.model.allowViewPreviousQuestions"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Students can navigate to previously answered questions </label>
                            </div>
                        </div>
                        <div class="marginTop30">
                            <div>
                                <h3 class="control-label">Instructions</h3>
                                <template v-if="form.model.peerEvaluationV2Instruction">
                                    <div v-html="form.model.peerEvaluationV2Instruction" />
                                </template>
                                <template v-else>
                                    <p>-</p>
                                </template>
                            </div>

                            <div class="marginTop15">
                                <h3 class="control-label">Attachments</h3>
                                <div>
                                    <div v-if="form.model.iratAttachments.length != 0" class="flex align-items">
                                        <div class="col-xs-12">
                                            <div v-for="(file, idx) in form.model.iratAttachments" :key="idx" class="flexSpaceBetween">
                                                <div>
                                                    <span>{{ idx + 1 }}.&nbsp;</span>
                                                    <span class="marginRight5">
                                                        <i class="fa fa-file-image-o" aria-hidden="true" />
                                                    </span>
                                                    <a :href="file.url" target="_blank" download>{{ file.filename }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-else>
                                        <div>-</div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </template>
        </kr-panel>

        <kr-panel v-if="activityObj.type == 'irat' || activityObj.type == 'iratntrat'" :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <div class="col-xs-12 col-sm-6">
                    <h2>IRAT Settings</h2>
                </div>

                <div v-if="pageType == 'confirmation'" class="col-xs-12 col-sm-6 flex gap10" :class="{ justifyEnd: !isBelow768px }">
                    <template v-if="iratMode == 'edit'">
                        <button class="btn btn-success margin0 flexInherit" @click.prevent="submitActivitySettings('irat')">Update</button>
                        <button class="btn btn-default margin0 flexInherit" exact @click.prevent="iratMode = 'view'">Cancel</button>
                    </template>
                    <template v-else="iratMode == 'view'">
                        <button
                            class="btn btn-default margin0 flexInherit"
                            @click.prevent="
                                iratMode = 'edit';
                                tratMode = 'view';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                        </button>
                    </template>
                </div>
            </template>
            <template #content>
                <template v-if="!isSet && iratMode == 'view' && pageType == 'confirmation'"> Not Set </template>
                <template v-else>
                    <template v-if="pageType == 'form' || (pageType == 'confirmation' && iratMode == 'edit')">
                        <div class="flex">
                            <div class="col-xs-12 col-md-6">
                                <fieldset>
                                    <legend>
                                        <h3>Behavior Settings</h3>
                                    </legend>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="iratAllowChangeAnswersBeforeFinalSubmission"
                                            v-model="form.model.iratAllowChangeAnswersBeforeFinalSubmission"
                                            type="checkbox"
                                            class="marginRight10"
                                            name="iratAllowChangeAnswersBeforeFinalSubmission"
                                            :class="{ disabled: form.model.iratImmediateFeedback }"
                                            :aria-disabled="form.model.iratImmediateFeedback"
                                            :aria-checked="form.model.iratAllowChangeAnswersBeforeFinalSubmission"
                                            @input="waitToSave()"
                                            @click="form.model.iratImmediateFeedback ? $event.preventDefault() : ''"
                                        />
                                        <label for="iratAllowChangeAnswersBeforeFinalSubmission" class="d-block">
                                            <b>Students can change their answers</b>
                                            <br />
                                            <i v-if="form.model.iratImmediateFeedback" class="fs-16px">
                                                (Reason for disabling: Endable Immediate Feedback is enabled)
                                            </i>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="iratAllowViewQuestionsAhead"
                                            v-model="form.model.iratAllowViewQuestionsAhead"
                                            type="checkbox"
                                            :true-value="false"
                                            :false-value="true"
                                            name="iratAllowViewQuestionsAhead"
                                            :aria-checked="form.model.iratAllowViewQuestionsAhead"
                                            @input="waitToSave()"
                                            @change="form.model.iratAllowViewQuestionsAhead ? (form.model.iratAllowViewPreviousQuestions = true) : ''"
                                        />
                                        <label for="iratAllowViewQuestionsAhead">
                                            <b>Students must answer questions in sequence</b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginLeft30 marginBottom15 flex">
                                        <input
                                            id="iratAllowViewPreviousQuestions"
                                            v-model="form.model.iratAllowViewPreviousQuestions"
                                            type="checkbox"
                                            name="iratAllowViewPreviousQuestions"
                                            :class="{ disabled: form.model.iratAllowViewQuestionsAhead }"
                                            :aria-disabled="form.model.iratAllowViewQuestionsAhead"
                                            :aria-checked="form.model.iratAllowViewPreviousQuestions"
                                            @input="waitToSave()"
                                            @click="form.model.iratAllowViewQuestionsAhead ? $event.preventDefault() : ''"
                                        />
                                        <label for="iratAllowViewPreviousQuestions" class="d-block">
                                            <b>Students can navigate to previously answered questions</b>
                                            <br />
                                            <i v-if="form.model.iratAllowViewQuestionsAhead" class="fs-16px">
                                                (Reason for disabling: Students must answer questions in sequence is not enabled)
                                            </i>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="iratRandomizeAnswerOptionOrder"
                                            v-model="form.model.iratRandomizeAnswerOptionOrder"
                                            type="checkbox"
                                            name="iratRandomizeAnswerOptionOrder"
                                            :aria-checked="form.model.iratRandomizeAnswerOptionOrder"
                                            @change="
                                                form.model.iratRandomizeAnswerOptionOrder
                                                    ? (form.model.iratHideQuestionOptionLabels = true)
                                                    : (form.model.iratHideQuestionOptionLabels = false)
                                            "
                                            @input="waitToSave()"
                                        />
                                        <label for="iratRandomizeAnswerOptionOrder">
                                            <b>Randomise choices</b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginLeft30 marginBottom15 flex">
                                        <input
                                            id="iratHideQuestionOptionLabels"
                                            v-model="form.model.iratHideQuestionOptionLabels"
                                            type="checkbox"
                                            name="iratHideQuestionOptionLabels"
                                            :class="{ disabled: !form.model.iratRandomizeAnswerOptionOrder }"
                                            :aria-disabled="!form.model.iratRandomizeAnswerOptionOrder"
                                            :aria-checked="form.model.iratHideQuestionOptionLabels"
                                            @input="waitToSave()"
                                            @click="!form.model.iratRandomizeAnswerOptionOrder ? $event.preventDefault() : ''"
                                        />
                                        <label for="iratHideQuestionOptionLabels" class="d-block">
                                            <b>Hide choice key (e.g. A, B, C ...)</b>
                                            <br />
                                            <i v-if="!form.model.iratRandomizeAnswerOptionOrder" class="fs-16px">
                                                (Reason for disabling: Randomise choices is not enabled)
                                            </i>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="iratImmediateFeedback"
                                            v-model="form.model.iratImmediateFeedback"
                                            type="checkbox"
                                            name="iratImmediateFeedback"
                                            :class="{ disabled: form.model.iratConfidenceBasedTesting }"
                                            :aria-disabled="form.model.iratConfidenceBasedTesting"
                                            :aria-checked="form.model.iratImmediateFeedback"
                                            @change="
                                                form.model.iratImmediateFeedback
                                                    ? (form.model.iratAllowChangeAnswersBeforeFinalSubmission = false) &&
                                                      (form.model.iratConfidenceBasedTesting = false)
                                                    : ''
                                            "
                                            @input="waitToSave()"
                                            @click="form.model.iratConfidenceBasedTesting ? $event.preventDefault() : ''"
                                        />
                                        <label for="iratImmediateFeedback" class="d-block">
                                            <b>Enable Immediate feedback</b>
                                            <br />
                                            <i v-if="form.model.iratConfidenceBasedTesting" class="fs-16px">
                                                (Reason for disabling: Use Confidence-based Testing is endabled)
                                            </i>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="iratEnableHintAndExplanation"
                                            v-model="form.model.iratEnableHintAndExplanation"
                                            type="checkbox"
                                            name="iratEnableHintAndExplanation"
                                            :aria-checked="form.model.iratEnableHintAndExplanation"
                                            @input="waitToSave()"
                                        />
                                        <label for="iratEnableHintAndExplanation" class="d-block">
                                            <b>Shows hints and explanations for questions that have them</b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginLeft30 marginBottom15 flex">
                                        <input
                                            id="iratShowExplanationsAfterTestHasCompleted"
                                            v-model="form.model.iratShowExplanationsAfterTestHasCompleted"
                                            type="checkbox"
                                            name="iratShowExplanationsAfterTestHasCompleted"
                                            :class="{ disabled: !form.model.iratEnableHintAndExplanation }"
                                            :aria-disabled="!form.model.iratEnableHintAndExplanation"
                                            :aria-checked="form.model.iratShowExplanationsAfterTestHasCompleted"
                                            @input="waitToSave()"
                                            @click="!form.model.iratEnableHintAndExplanation ? $event.preventDefault() : ''"
                                        />
                                        <label for="iratShowExplanationsAfterTestHasCompleted" class="d-block">
                                            <b>Show after activity has ended.</b>
                                            <br v-if="activityObj.type == 'iratntrat'" />
                                            <i v-if="activityObj.type == 'iratntrat'" class="greyText">
                                                The answer will only be displayed once the TRAT has ended.
                                            </i>
                                            <br />
                                            <i v-if="!form.model.iratEnableHintAndExplanation" class="fs-16px">
                                                (Reason for disabling: Shows hints and explanations for questions that have them is not enabled)
                                            </i>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginLeft30 marginBottom15 flex">
                                        <input
                                            id="iratShowHintsAndExplanationsDuringTest"
                                            v-model="form.model.iratShowHintsAndExplanationsDuringTest"
                                            type="checkbox"
                                            name="iratShowHintsAndExplanationsDuringTest"
                                            :class="{ disabled: !form.model.iratEnableHintAndExplanation || !form.model.iratImmediateFeedback }"
                                            :aria-disabled="!form.model.iratEnableHintAndExplanation || !form.model.iratImmediateFeedback"
                                            :aria-checked="form.model.iratShowHintsAndExplanationsDuringTest"
                                            @input="waitToSave()"
                                            @click="
                                                !form.model.iratEnableHintAndExplanation || !form.model.iratImmediateFeedback ? $event.preventDefault() : ''
                                            "
                                        />
                                        <label for="iratShowHintsAndExplanationsDuringTest" class="d-block">
                                            <b>Show immediate feedback during test</b>
                                            <br />
                                            <i v-if="!form.model.iratEnableHintAndExplanation || !form.model.iratImmediateFeedback" class="fs-16px">
                                                (Reason for disabling:
                                                <template v-if="!form.model.iratEnableHintAndExplanation">
                                                    Shows hints and explanations for questions that have them
                                                </template>
                                                <template v-else-if="!form.model.iratImmediateFeedback"> Enable Immediate feedback </template>
                                                is not enabled)
                                            </i>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="iratConfidenceBasedTesting"
                                            v-model="form.model.iratConfidenceBasedTesting"
                                            type="checkbox"
                                            name="iratConfidenceBasedTesting"
                                            :class="{ disabled: form.model.iratImmediateFeedback }"
                                            :aria-disabled="form.model.iratImmediateFeedback"
                                            :aria-checked="form.model.iratConfidenceBasedTesting"
                                            :disabled="form.model.iratImmediateFeedback ? true : undefined"
                                            @change="form.model.iratConfidenceBasedTesting ? (form.model.iratImmediateFeedback = false) : ''"
                                            @input="waitToSave()"
                                            @click="form.model.iratImmediateFeedback ? $event.preventDefault() : ''"
                                        />
                                        <label for="iratConfidenceBasedTesting" class="d-block">
                                            <b>Use Confidence-based Testing</b>
                                            <br />
                                            <i v-if="form.model.iratImmediateFeedback" class="fs-16px">
                                                (Reason for disabling: Endable Immediate Feedback is enabled)
                                            </i>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="iratAllowSafeExamBrowserOnly"
                                            v-model="form.model.iratAllowSafeExamBrowserOnly"
                                            type="checkbox"
                                            name="iratAllowSafeExamBrowserOnly"
                                            :aria-checked="form.model.iratAllowSafeExamBrowserOnly"
                                            @input="waitToSave()"
                                        />
                                        <label for="iratAllowSafeExamBrowserOnly">
                                            <b>Students must answer using Safe Exam Browser</b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="iratAllowPdfDownload"
                                            v-model="form.model.iratAllowPdfDownload"
                                            type="checkbox"
                                            name="iratAllowPdfDownload"
                                            :aria-checked="form.model.iratAllowPdfDownload"
                                            @input="waitToSave()"
                                        />
                                        <label for="iratAllowPdfDownload">
                                            <b>Allow students to download and/or print PDF documents</b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="iratEnableImagePointerEvents"
                                            v-model="form.model.iratEnableImagePointerEvents"
                                            type="checkbox"
                                            name="iratEnableImagePointerEvents"
                                            :aria-checked="form.model.iratEnableImagePointerEvents"
                                            @input="waitToSave()"
                                        />
                                        <label for="iratEnableImagePointerEvents">
                                            <b>Allow students to download and/or print images from questions</b>
                                        </label>
                                    </div>
                                </fieldset>

                                <div class="marginTop15">
                                    <div>
                                        <kr-field
                                            :form="form"
                                            display="Instructions"
                                            name="iratInstruction"
                                            :options="{
                                                htmlEditor: true,
                                                placeholder: 'Enter instructions for this activity here',
                                                hasLabel: false,
                                                showError: false,
                                            }"
                                        />
                                    </div>
                                    <div class="marginTop15">
                                        <kr-file-multiple
                                            :options="{ reverse: true }"
                                            :form="form"
                                            name="iratAttachments"
                                            @preview-attachment="showAttachment"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-6">
                                <h3>Student App Preview</h3>

                                <div class="expectation-paragraph">
                                    <iframe
                                        id="irat_iframe"
                                        height="600px"
                                        width="100%"
                                        :src="`${APP_URL}/in/teachers/tests/${$route.params.id}/preview-rat?type=irat`"
                                        title="Student App Preview"
                                        @load="loadIframe()"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div>
                            <h3 class="control-label">Behavior Settings</h3>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.iratAllowChangeAnswersBeforeFinalSubmission"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Students can change their answers </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="!form.model.iratAllowViewQuestionsAhead"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Students must answer questions in sequence </label>
                            </div>

                            <div class="marginBottom15 marginLeft30">
                                <label class="marginRight10">
                                    <template v-if="form.model.iratAllowViewPreviousQuestions"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Students can navigate to previously answered questions </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.iratRandomizeAnswerOptionOrder"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Randomise choices </label>
                            </div>

                            <div class="marginBottom15 marginLeft30">
                                <label class="marginRight10">
                                    <template v-if="form.model.iratHideQuestionOptionLabels"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Hide choice key (e.g. A, B, C ...) </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.iratImmediateFeedback"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Enable Immediate feedback </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.iratEnableHintAndExplanation"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Shows hints and explanations for questions that have them </label>
                            </div>

                            <div class="marginBottom15 marginLeft30">
                                <label class="marginRight10">
                                    <template v-if="form.model.iratShowExplanationsAfterTestHasCompleted"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Show after activity has ended </label>
                            </div>

                            <div class="marginBottom15 marginLeft30">
                                <label class="marginRight10">
                                    <template v-if="form.model.iratShowHintsAndExplanationsDuringTest"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Show immediate feedback during test </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.iratConfidenceBasedTesting"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Use Confidence-based Testing </label>
                            </div>
                            <div v-if="!hideSafeExamBrowserField">
                                <label class="marginRight10">
                                    <template v-if="form.model.iratAllowSafeExamBrowserOnly"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Students must answer using Safe Exam Browser </label>
                            </div>
                            <div>
                                <label class="marginRight10">
                                    <template v-if="form.model.iratAllowPdfDownload"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Allow students to download and/or print PDF documents </label>
                            </div>
                            <div>
                                <label class="marginRight10">
                                    <template v-if="form.model.iratEnableImagePointerEvents"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Allow students to download and/or print images from questions</label>
                            </div>
                        </div>

                        <div class="marginTop30">
                            <div>
                                <h3 class="control-label">Instruction</h3>
                                <template v-if="form.model.iratInstruction">
                                    <div v-html="form.model.iratInstruction" />
                                </template>
                                <template v-else>
                                    <div>-</div>
                                </template>
                            </div>

                            <div class="marginTop15">
                                <h3 class="control-label">Attachments</h3>
                                <div>
                                    <div v-if="form.model.iratAttachments.length != 0" class="flex align-items">
                                        <div class="col-xs-12">
                                            <div v-for="(file, idx) in form.model.iratAttachments" :key="idx" class="flexSpaceBetween">
                                                <div>
                                                    <span>{{ idx + 1 }}.&nbsp;</span>
                                                    <span class="marginRight5">
                                                        <i class="fa fa-file-image-o" aria-hidden="true" />
                                                    </span>
                                                    <a :href="file.url" target="_blank" download>{{ file.filename }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-else>
                                        <div>-</div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </template>
        </kr-panel>

        <kr-panel
            v-if="activityObj.type == 'trat' || activityObj.type == 'iratntrat'"
            :with-footer="false"
            :options="{ canToggle: false }"
            :class="{ marginTop20: pageType != 'form' }"
        >
            <template #title>
                <div class="col-xs-12 col-sm-6">
                    <h2>TRAT Settings</h2>
                </div>

                <div v-if="pageType == 'confirmation'" class="col-xs-12 col-sm-6 flex gap10" :class="{ justifyEnd: !isBelow768px }">
                    <template v-if="tratMode == 'edit'">
                        <button class="btn btn-success margin0 flexInherit" @click.prevent="submitActivitySettings('trat')">Update</button>
                        <button class="btn btn-default margin0 flexInherit" exact @click.prevent="tratMode = 'view'">Cancel</button>
                    </template>
                    <template v-else="tratMode == 'view'">
                        <button
                            class="btn btn-default margin0 flexInherit"
                            @click.prevent="
                                iratMode = 'view';
                                tratMode = 'edit';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                        </button>
                    </template>
                </div>
            </template>
            <template #content>
                <template v-if="!isSet && tratMode == 'view' && pageType == 'confirmation'"> Not Set </template>
                <template v-else>
                    <template v-if="pageType == 'form' || (pageType == 'confirmation' && tratMode == 'edit')">
                        <div class="flex">
                            <div class="col-xs-12 col-md-6">
                                <fieldset>
                                    <legend>
                                        <h3>Behavior Settings</h3>
                                    </legend>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="tratAllowStudentsToUploadFiles"
                                            v-model="form.model.tratAllowStudentsToUploadFiles"
                                            type="checkbox"
                                            class="marginRight10"
                                            :aria-checked="form.model.tratAllowStudentsToUploadFiles"
                                            name="tratAllowStudentsToUploadFiles"
                                            @input="waitToSave()"
                                        />
                                        <label for="tratAllowStudentsToUploadFiles" class="d-block">
                                            <b>Teams can upload files</b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="tratAllowTeamClarifications"
                                            v-model="form.model.tratAllowTeamClarifications"
                                            type="checkbox"
                                            class="marginRight10"
                                            :aria-checked="form.model.tratAllowTeamClarifications"
                                            name="tratAllowTeamClarifications"
                                            @input="waitToSave()"
                                        />
                                        <label for="tratAllowTeamClarifications" class="d-block">
                                            <b>Teams can submit clarifications for questions</b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="tratIsSyncToTeamQuestion"
                                            v-model="form.model.tratIsSyncToTeamQuestion"
                                            type="checkbox"
                                            class="marginRight10"
                                            :aria-checked="form.model.tratIsSyncToTeamQuestion"
                                            name="tratIsSyncToTeamQuestion"
                                            @input="waitToSave()"
                                        />
                                        <label for="tratIsSyncToTeamQuestion" class="d-block">
                                            <b>Team members will see what the team reporter sees</b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="tratAllowViewQuestionsAhead"
                                            v-model="form.model.tratAllowViewQuestionsAhead"
                                            type="checkbox"
                                            class="marginRight10"
                                            :true-value="false"
                                            :false-value="true"
                                            :aria-checked="form.model.tratAllowViewQuestionsAhead"
                                            name="tratAllowViewQuestionsAhead"
                                            @input="waitToSave()"
                                            @change="form.model.tratAllowViewQuestionsAhead ? (form.model.tratAllowViewPreviousQuestions = true) : ''"
                                        />
                                        <label for="tratAllowViewQuestionsAhead" class="d-block">
                                            <b>Teams must answer questions in sequence</b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginLeft30 marginBottom15 flex">
                                        <input
                                            id="tratAllowViewPreviousQuestions"
                                            v-model="form.model.tratAllowViewPreviousQuestions"
                                            type="checkbox"
                                            class="marginRight10"
                                            name="tratAllowViewPreviousQuestions"
                                            :class="{ disabled: form.model.tratAllowViewQuestionsAhead }"
                                            :aria-disabled="form.model.tratAllowViewQuestionsAhead"
                                            :aria-checked="form.model.tratAllowViewPreviousQuestions"
                                            @input="waitToSave()"
                                            @click="form.model.tratAllowViewQuestionsAhead ? $event.preventDefault() : ''"
                                        />
                                        <label for="tratAllowViewPreviousQuestions" class="d-block">
                                            <b>Teams can navigate to previously answered questions</b>
                                            <br />
                                            <i v-if="form.model.tratAllowViewQuestionsAhead" class="fs-16px">
                                                (Reason for disabling: Teams must answer questions in sequence is enabled)
                                            </i>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="tratEnableHintAndExplanation"
                                            v-model="form.model.tratEnableHintAndExplanation"
                                            type="checkbox"
                                            class="marginRight10"
                                            :aria-checked="form.model.tratEnableHintAndExplanation"
                                            name="tratEnableHintAndExplanation"
                                            @input="waitToSave()"
                                        />
                                        <label for="tratEnableHintAndExplanation" class="d-block">
                                            <b>Shows hints and explanations for questions that have them</b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginLeft30 marginBottom15 flex">
                                        <input
                                            id="tratShowExplanationsAfterTestHasCompleted"
                                            v-model="form.model.tratShowExplanationsAfterTestHasCompleted"
                                            type="checkbox"
                                            name="tratShowExplanationsAfterTestHasCompleted"
                                            :class="{ disabled: !form.model.tratEnableHintAndExplanation }"
                                            :aria-disabled="!form.model.tratEnableHintAndExplanation"
                                            :aria-checked="form.model.tratShowExplanationsAfterTestHasCompleted"
                                            @input="waitToSave()"
                                            @click="!form.model.tratEnableHintAndExplanation ? $event.preventDefault() : ''"
                                        />
                                        <label for="tratShowExplanationsAfterTestHasCompleted" class="d-block">
                                            <b>Show after activity has ended.</b>
                                            <br />
                                            <i v-if="!form.model.tratEnableHintAndExplanation" class="fs-16px">
                                                (Reason for disabling: Shows hints and explanations for questions that have them is not enabled)
                                            </i>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginLeft30 marginBottom15 flex">
                                        <input
                                            id="tratShowHintsAndExplanationsDuringTest"
                                            v-model="form.model.tratShowHintsAndExplanationsDuringTest"
                                            type="checkbox"
                                            name="tratShowHintsAndExplanationsDuringTest"
                                            :class="{ disabled: !form.model.tratEnableHintAndExplanation }"
                                            :aria-disabled="!form.model.tratEnableHintAndExplanation"
                                            :aria-checked="form.model.tratShowHintsAndExplanationsDuringTest"
                                            @input="waitToSave()"
                                            @click="!form.model.tratEnableHintAndExplanation ? $event.preventDefault() : ''"
                                        />
                                        <label for="tratShowHintsAndExplanationsDuringTest" class="d-block">
                                            <b>Show immediate feedback during test</b>
                                            <br />
                                            <i v-if="!form.model.tratEnableHintAndExplanation" class="fs-16px">
                                                (Reason for disabling: Shows hints and explanations for questions that have them is not enabled)
                                            </i>
                                        </label>
                                    </div>

                                    <div v-if="!hideSafeExamBrowserField" class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="tratAllowSafeExamBrowserOnly"
                                            v-model="form.model.tratAllowSafeExamBrowserOnly"
                                            type="checkbox"
                                            class="marginRight10"
                                            :aria-checked="form.model.tratAllowSafeExamBrowserOnly"
                                            name="tratAllowSafeExamBrowserOnly"
                                            @input="waitToSave()"
                                        />
                                        <label for="tratAllowSafeExamBrowserOnly" class="d-block">
                                            <b>Teams must answer using Safe Exam Browser</b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="tratAllowPdfDownload"
                                            v-model="form.model.tratAllowPdfDownload"
                                            type="checkbox"
                                            class="marginRight10"
                                            :aria-checked="form.model.tratAllowPdfDownload"
                                            name="tratAllowPdfDownload"
                                            @input="waitToSave()"
                                        />
                                        <label for="tratAllowPdfDownload" class="d-block">
                                            <b>Allow students to download and/or print PDF documents</b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="tratRandomizeAnswerOptionOrder"
                                            v-model="form.model.tratRandomizeAnswerOptionOrder"
                                            type="checkbox"
                                            class="marginRight10"
                                            :aria-checked="form.model.tratRandomizeAnswerOptionOrder"
                                            name="tratRandomizeAnswerOptionOrder"
                                            @input="waitToSave()"
                                            @change="
                                                form.model.tratRandomizeAnswerOptionOrder
                                                    ? (form.model.tratHideQuestionOptionLabels = true)
                                                    : (form.model.tratHideQuestionOptionLabels = false)
                                            "
                                        />
                                        <label for="tratRandomizeAnswerOptionOrder" class="d-block">
                                            <b>Randomise choices</b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginLeft30 marginBottom15 flex">
                                        <input
                                            id="tratHideQuestionOptionLabels"
                                            v-model="form.model.tratHideQuestionOptionLabels"
                                            type="checkbox"
                                            class="marginRight10"
                                            name="tratHideQuestionOptionLabels"
                                            :class="{ disabled: !form.model.tratRandomizeAnswerOptionOrder }"
                                            :aria-disabled="!form.model.tratRandomizeAnswerOptionOrder"
                                            :aria-checked="form.model.tratHideQuestionOptionLabels"
                                            @input="waitToSave()"
                                            @click="!form.model.tratRandomizeAnswerOptionOrder ? $event.preventDefault() : ''"
                                        />
                                        <label for="tratHideQuestionOptionLabels" class="d-block">
                                            <b>Hide choice key (e.g. A, B, C ...)</b>
                                            <br />
                                            <i v-if="!form.model.tratRandomizeAnswerOptionOrder" class="fs-16px">
                                                (Reason for disabling: Randomise choices is not enabled)
                                            </i>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="tratEnableImagePointerEvents"
                                            v-model="form.model.tratEnableImagePointerEvents"
                                            type="checkbox"
                                            class="marginRight10"
                                            :aria-checked="form.model.tratEnableImagePointerEvents"
                                            name="tratEnableImagePointerEvents"
                                            @input="waitToSave()"
                                        />
                                        <label for="tratEnableImagePointerEvents" class="d-block">
                                            <b>Allow students to download and/or print images from questions</b>
                                        </label>
                                    </div>
                                </fieldset>

                                <div class="marginTop15">
                                    <kr-field
                                        :form="form"
                                        display="Instructions"
                                        name="tratInstruction"
                                        :options="{
                                            htmlEditor: true,
                                            placeholder: 'Enter instructions for this activity here',
                                            hasLabel: false,
                                            showError: false,
                                        }"
                                    />
                                    <div class="marginTop15">
                                        <kr-file-multiple
                                            :options="{ reverse: true }"
                                            :form="form"
                                            name="tratAttachments"
                                            @preview-attachment="showAttachment"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-6">
                                <h3>Student App Preview</h3>

                                <div class="expectation-paragraph">
                                    <iframe
                                        id="trat_iframe"
                                        height="600px"
                                        width="100%"
                                        :src="`${APP_URL}/in/teachers/tests/${$route.params.id}/preview-rat?type=trat`"
                                        title="Student App Preview"
                                        @load="loadIframe()"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-else>
                        <h3 class="control-label">Behavior Settings</h3>

                        <div>
                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.tratAllowStudentsToUploadFiles"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Teams can upload files </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.tratAllowTeamClarifications"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Teams can submit clarifications for questions </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.tratIsSyncToTeamQuestion"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Team members will see what the team reporter sees </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="!form.model.tratAllowViewQuestionsAhead"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Teams must answer questions in sequence </label>
                            </div>

                            <div class="marginLeft20 marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.tratAllowViewPreviousQuestions"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Teams can navigate to previously answered questions </label>
                            </div>
                        </div>

                        <div class="marginBottom15">
                            <label class="marginRight10">
                                <template v-if="form.model.tratEnableHintAndExplanation"> Yes </template>
                                <template v-else>No</template>
                            </label>
                            <label class="control-label fontBlack"> Shows hints and explanations for questions that have them </label>
                        </div>

                        <div class="marginBottom15 marginLeft30">
                            <label class="marginRight10">
                                <template v-if="form.model.tratShowExplanationsAfterTestHasCompleted"> Yes </template>
                                <template v-else>No</template>
                            </label>
                            <label class="control-label fontBlack"> Show after activity has ended </label>
                        </div>

                        <div class="marginBottom15 marginLeft30">
                            <label class="marginRight10">
                                <template v-if="form.model.tratShowHintsAndExplanationsDuringTest"> Yes </template>
                                <template v-else>No</template>
                            </label>
                            <label class="control-label fontBlack"> Show immediate feedback during test </label>
                        </div>

                        <div v-if="!hideSafeExamBrowserField" class="marginBottom15">
                            <label class="marginRight10">
                                <template v-if="form.model.tratAllowSafeExamBrowserOnly"> Yes </template>
                                <template v-else>No</template>
                            </label>
                            <label class="control-label fontBlack"> Teams must answer using Safe Exam Browser </label>
                        </div>
                        <div class="marginBottom15">
                            <label class="marginRight10">
                                <template v-if="form.model.tratAllowPdfDownload"> Yes </template>
                                <template v-else>No</template>
                            </label>
                            <label class="control-label fontBlack"> Allow students to download and/or print PDF documents </label>
                        </div>
                        <div>
                            <label class="marginRight10">
                                <template v-if="form.model.tratEnableImagePointerEvents"> Yes </template>
                                <template v-else>No</template>
                            </label>
                            <label class="control-label fontBlack"> Allow students to download and/or print images from questions</label>
                        </div>
                        <div class="marginBottom15">
                            <label class="marginRight10">
                                <template v-if="form.model.tratRandomizeAnswerOptionOrder"> Yes </template>
                                <template v-else>No</template>
                            </label>
                            <label class="control-label fontBlack"> Randomise choices </label>
                        </div>

                        <div class="marginBottom15 marginLeft20">
                            <label class="marginRight10">
                                <template v-if="form.model.tratHideQuestionOptionLabels"> Yes </template>
                                <template v-else>No</template>
                            </label>
                            <label class="control-label fontBlack"> Hide choice key (e.g. A, B, C ...) </label>
                        </div>
                        <div class="marginTop30">
                            <h3 class="control-label">Instruction</h3>
                            <template v-if="form.model.tratInstruction">
                                <div v-html="form.model.tratInstruction" />
                            </template>
                            <template v-else>
                                <div>-</div>
                            </template>

                            <div class="marginTop15">
                                <h3 class="control-label">Attachment</h3>
                                <div>
                                    <div v-if="form.model.tratAttachments.length != 0" class="flex align-items">
                                        <div class="col-xs-12">
                                            <div v-for="(file, idx) in form.model.tratAttachments" :key="idx" class="flexSpaceBetween">
                                                <div>
                                                    <span>{{ idx + 1 }}.&nbsp;</span>
                                                    <span class="marginRight5">
                                                        <i class="fa fa-file-image-o" aria-hidden="true" />
                                                    </span>
                                                    <a :href="file.url" target="_blank" download>{{ file.filename }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-else>
                                        <div>-</div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </kr-panel>

        <kr-panel v-if="activityObj.type == 'prework'" :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <template v-if="!isSet && preworkMode == 'view' && pageType == 'confirmation'"> Not Set </template>
                <template v-else>
                    <div class="col-xs-12 col-sm-6">
                        <h2>Prework Settings</h2>
                    </div>

                    <div v-if="pageType == 'confirmation'" class="col-xs-12 col-sm-6 flex gap10" :class="{ justifyEnd: !isBelow768px }">
                        <template v-if="preworkMode == 'edit'">
                            <button class="btn btn-success margin0 flexInherit" @click.prevent="submitActivitySettings('prework')">Update</button>
                            <button class="btn btn-default margin0 flexInherit" exact @click.prevent="preworkMode = 'view'">Cancel</button>
                        </template>
                        <template v-if="preworkMode == 'view'">
                            <button
                                class="btn btn-default margin0 flexInherit"
                                @click.prevent="
                                    preworkMode = 'edit';
                                    init();
                                "
                            >
                                <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                            </button>
                        </template>
                    </div>
                </template>
            </template>
            <template #content>
                <template v-if="!isSet && preworkMode == 'view' && pageType == 'confirmation'"> Not Set </template>
                <template v-else>
                    <div v-if="pageType == 'form' || preworkMode == 'edit'">
                        <div class="flex">
                            <div class="col-xs-12 col-md-6">
                                <fieldset>
                                    <legend>
                                        <h3>Behavior Settings</h3>
                                    </legend>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="preworkAllowPdfDownload"
                                            v-model="form.model.preworkAllowPdfDownload"
                                            type="checkbox"
                                            class="marginRight10"
                                            :aria-checked="form.model.preworkAllowPdfDownload"
                                            name="preworkAllowPdfDownload"
                                            @input="waitToSave()"
                                        />
                                        <label for="preworkAllowPdfDownload" class="d-block">
                                            <b>Allow students to download and/or print PDF documents</b>
                                        </label>
                                    </div>
                                </fieldset>

                                <kr-field
                                    :form="form"
                                    display="Instruction"
                                    class="marginTop15"
                                    name="preworkInstruction"
                                    :options="{
                                        htmlEditor: true,
                                        placeholder: 'Enter instructions for this activity here',
                                        hasLabel: false,
                                        showError: false,
                                    }"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <h3 class="control-label">Behavior Settings</h3>
                        <div class="marginBottom15">
                            <label class="marginRight10">
                                <template v-if="form.model.preworkAllowPdfDownload"> Yes </template>
                                <template v-else>No</template>
                            </label>
                            <label class="control-label fontBlack"> Allow students to download and/or print PDF documents </label>
                        </div>
                        <h3 class="control-label">Instrcution</h3>
                        <template v-if="form.model.preworkInstruction">
                            <div v-html="form.model.preworkInstruction" />
                        </template>
                        <template v-else>
                            <div>-</div>
                        </template>
                    </div>

                    <div v-if="pageType == 'form' || preworkMode == 'edit'" class="marginTop15">
                        <kr-file-multiple :options="{ reverse: true }" :form="form" name="preworkAttachments" @preview-attachment="showAttachment" />
                    </div>
                    <div v-else class="marginTop15">
                        <h3 class="control-label">Attachments</h3>
                        <div>
                            <div v-if="form.model.preworkAttachments.length != 0" class="flex align-items">
                                <div class="col-xs-12">
                                    <div v-for="(file, idx) in form.model.preworkAttachments" :key="idx" class="flexSpaceBetween">
                                        <div>
                                            <span>{{ idx + 1 }}.&nbsp;</span>
                                            <span class="marginRight10">
                                                <i class="fa fa-file-image-o" aria-hidden="true" />
                                            </span>
                                            <a :href="file.url" target="_blank" download>{{ file.filename }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template v-else>
                                <div>-</div>
                            </template>
                        </div>
                    </div>
                </template>
            </template>
        </kr-panel>

        <kr-panel v-if="activityObj.type == 'application'" :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <div class="col-xs-12 col-sm-6">
                    <h2>Application Case Settings</h2>
                </div>

                <div v-if="pageType == 'confirmation'" class="col-xs-12 col-sm-6 flex gap10" :class="{ justifyEnd: !isBelow768px }">
                    <template v-if="applicationMode == 'edit'">
                        <button class="btn btn-success margin0 flexInherit" @click.prevent="submitActivitySettings('application')">Update</button>
                        <button class="btn btn-default margin0 flexInherit" exact @click.prevent="applicationMode = 'view'">Cancel</button>
                    </template>
                    <template v-if="applicationMode == 'view'">
                        <button
                            class="btn btn-default margin0 flexInherit"
                            @click.prevent="
                                applicationMode = 'edit';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                        </button>
                    </template>
                </div>
            </template>
            <template #content>
                <template v-if="!isSet && applicationMode == 'view' && pageType == 'confirmation'"> Not Set </template>
                <template v-else>
                    <template v-if="pageType == 'form' || (pageType == 'confirmation' && applicationMode == 'edit')">
                        <div class="flex">
                            <div class="col-xs-12 col-md-6">
                                <fieldset>
                                    <legend>
                                        <h3>Behavior Settings</h3>
                                    </legend>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="applicationType"
                                            v-model="form.model.others.applicationType"
                                            type="checkbox"
                                            true-value="team"
                                            false-value="individual"
                                            class="marginRight10"
                                            :aria-checked="form.model.others.applicationType == 'team' ? true : false"
                                            name="applicationType"
                                            @change="changedApplicationType()"
                                            @input="waitToSave()"
                                        />
                                        <label for="applicationType" class="d-block">
                                            <b>This is a team-based activity</b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginLeft30 marginBottom15 flex">
                                        <input
                                            id="applicationIsSyncToTeamQuestion"
                                            v-model="form.model.applicationIsSyncToTeamQuestion"
                                            type="checkbox"
                                            class="marginRight10"
                                            name="applicationIsSyncToTeamQuestion"
                                            :class="{ disabled: form.model.others.applicationType != 'team' }"
                                            :aria-disabled="form.model.others.applicationType != 'team' ? true : undefined"
                                            :aria-checked="form.model.applicationIsSyncToTeamQuestion"
                                            @input="waitToSave()"
                                            @click="form.model.others.applicationType != 'team' ? $event.preventDefault() : ''"
                                        />
                                        <label for="applicationIsSyncToTeamQuestion" class="d-block">
                                            <b>Team members will see what the team reporter sees</b>
                                            <br />
                                            <i v-if="form.model.others.applicationType != 'team'" class="fs-16px">
                                                (Reason for disabling: This is a team-based activity)
                                            </i>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="isApplicationGraded"
                                            v-model="form.model.others.isApplicationGraded"
                                            type="checkbox"
                                            class="marginRight10"
                                            :aria-checked="form.model.others.isApplicationGraded"
                                            name="isApplicationGraded"
                                            @input="waitToSave()"
                                        />
                                        <label for="isApplicationGraded" class="d-block">
                                            <b>This activity is graded</b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="applicationAllowStudentsToUploadFiles"
                                            v-model="form.model.applicationAllowStudentsToUploadFiles"
                                            type="checkbox"
                                            class="marginRight10"
                                            :aria-checked="form.model.applicationAllowStudentsToUploadFiles"
                                            name="applicationAllowStudentsToUploadFiles"
                                            @input="waitToSave()"
                                        />
                                        <label for="applicationAllowStudentsToUploadFiles" class="d-block">
                                            <b>
                                                <template v-if="form.model.others.applicationType == 'team'"> Teams </template>
                                                <template v-else> Students </template>
                                                can upload files
                                            </b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="applicationAllowChangeAnswersBeforeFinalSubmission"
                                            v-model="form.model.applicationAllowChangeAnswersBeforeFinalSubmission"
                                            type="checkbox"
                                            class="marginRight10"
                                            :aria-checked="form.model.applicationAllowChangeAnswersBeforeFinalSubmission"
                                            name="applicationAllowChangeAnswersBeforeFinalSubmission"
                                            @input="waitToSave()"
                                        />
                                        <label for="applicationAllowChangeAnswersBeforeFinalSubmission" class="d-block">
                                            <b>
                                                <template v-if="form.model.others.applicationType == 'team'"> Teams </template>
                                                <template v-else> Students </template>
                                                can change their answers
                                            </b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="applicationAllowViewQuestionsAhead"
                                            v-model="form.model.applicationAllowViewQuestionsAhead"
                                            type="checkbox"
                                            class="marginRight10"
                                            :aria-checked="form.model.applicationAllowViewQuestionsAhead"
                                            name="applicationAllowViewQuestionsAhead"
                                            :true-value="false"
                                            :false-value="true"
                                            @change="
                                                form.model.applicationAllowViewQuestionsAhead ? (form.model.applicationAllowViewPreviousQuestions = true) : ''
                                            "
                                            @input="waitToSave()"
                                        />
                                        <label for="applicationAllowViewQuestionsAhead" class="d-block">
                                            <b>
                                                <template v-if="form.model.others.applicationType == 'team'"> Teams </template>
                                                <template v-else> Students </template>
                                                must answer questions in sequence
                                            </b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginLeft30 marginBottom15 flex">
                                        <input
                                            id="applicationAllowViewPreviousQuestions"
                                            v-model="form.model.applicationAllowViewPreviousQuestions"
                                            type="checkbox"
                                            class="marginRight10"
                                            name="applicationAllowViewPreviousQuestions"
                                            :class="{ disabled: form.model.applicationAllowViewQuestionsAhead }"
                                            :aria-disabled="form.model.applicationAllowViewQuestionsAhead"
                                            :aria-checked="form.model.applicationAllowViewPreviousQuestions"
                                            @input="waitToSave()"
                                            @click="form.model.applicationAllowViewQuestionsAhead ? $event.preventDefault() : ''"
                                        />
                                        <label for="applicationAllowViewPreviousQuestions" class="d-block">
                                            <b>
                                                <template v-if="form.model.others.applicationType == 'team'"> Teams </template>
                                                <template v-else> Students </template>
                                                can navigate to previously answered questions
                                            </b>
                                            <br />
                                            <i v-if="form.model.applicationAllowViewQuestionsAhead" class="fs-16px">
                                                (Reason for disabling:
                                                <template v-if="form.model.others.applicationType == 'team'"> Teams </template>
                                                <template v-else> Students </template>
                                                must answer questions in sequence)
                                            </i>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="applicationConfidenceBasedTesting"
                                            v-model="form.model.applicationConfidenceBasedTesting"
                                            type="checkbox"
                                            class="marginRight10"
                                            :class="{ disabled: form.model.others.applicationType == 'team' }"
                                            :aria-checked="form.model.others.applicationConfidenceBasedTesting"
                                            :aria-disabled="form.model.others.applicationType == 'team'"
                                            name="applicationConfidenceBasedTesting"
                                            @input="waitToSave()"
                                            @click="form.model.others.applicationType == 'team' ? $event.preventDefault() : ''"
                                        />
                                        <label for="applicationConfidenceBasedTesting" class="d-block">
                                            <b> Use Confidence-based Testing for MCQ </b>
                                            <br />
                                            <i v-if="form.model.others.applicationType == 'team'" class="fs-16px">
                                                (Reason for disabling: This is a team-based activity)
                                            </i>
                                        </label>
                                    </div>

                                    <div v-if="!hideSafeExamBrowserField" class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="applicationAllowSafeExamBrowserOnly"
                                            v-model="form.model.applicationAllowSafeExamBrowserOnly"
                                            type="checkbox"
                                            class="marginRight10"
                                            :aria-checked="form.model.applicationConfidenceBasedTesting"
                                            name="applicationAllowSafeExamBrowserOnly"
                                            @input="waitToSave()"
                                        />
                                        <label for="applicationAllowSafeExamBrowserOnly" class="d-block">
                                            <b>
                                                <template v-if="form.model.others.applicationType == 'team'"> Teams </template>
                                                <template v-else> Students </template>
                                                must answer using Safe Exam Browser
                                            </b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="applicationAllowPdfDownload"
                                            v-model="form.model.applicationAllowPdfDownload"
                                            type="checkbox"
                                            class="marginRight10"
                                            :aria-checked="form.model.applicationAllowPdfDownload"
                                            name="applicationAllowPdfDownload"
                                            @input="waitToSave()"
                                        />
                                        <label for="applicationAllowPdfDownload" class="d-block">
                                            <b> Allow students to download and/or print PDF documents </b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="applicationEnableImagePointerEvents"
                                            v-model="form.model.applicationEnableImagePointerEvents"
                                            type="checkbox"
                                            class="marginRight10"
                                            :aria-checked="form.model.applicationEnableImagePointerEvents"
                                            name="applicationEnableImagePointerEvents"
                                            @input="waitToSave()"
                                        />
                                        <label for="applicationEnableImagePointerEvents" class="d-block">
                                            <b> Allow students to download and/or print images from questions </b>
                                        </label>
                                    </div>

                                    <div class="newCheckboxField smaller-ver marginBottom15 flex">
                                        <input
                                            id="applicationShowRequiredOptionsForMcqm"
                                            v-model="form.model.applicationShowRequiredOptionsForMcqm"
                                            type="checkbox"
                                            class="marginRight10"
                                            :aria-checked="form.model.applicationShowRequiredOptionsForMcqm"
                                            name="applicationShowRequiredOptionsForMcqm"
                                            @input="waitToSave()"
                                        />
                                        <label for="applicationShowRequiredOptionsForMcqm" class="d-block">
                                            <b> For MCQM, require to select exact number of correct answers </b>
                                        </label>
                                    </div>
                                </fieldset>

                                <div class="marginTop30">
                                    <kr-field
                                        :form="form"
                                        display="Instructions"
                                        name="applicationInstruction"
                                        :options="{
                                            htmlEditor: true,
                                            placeholder: 'Enter instructions for this activity here',
                                            hasLabel: false,
                                            showError: false,
                                        }"
                                    />
                                    <div class="marginTop15">
                                        <kr-file-multiple
                                            :options="{ reverse: true }"
                                            :form="form"
                                            name="applicationAttachments"
                                            @preview-attachment="showAttachment"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-6">
                                <h3>Student App Preview</h3>

                                <div class="expectation-paragraph">
                                    <iframe
                                        id="application_iframe"
                                        height="600px"
                                        width="100%"
                                        :src="`${APP_URL}/in/teachers/tests/${$route.params.id}/preview-rat`"
                                        title="Student App Preview"
                                        @load="loadIframe()"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-else>
                        <div>
                            <h3 class="control-label">Behavior Settings</h3>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.others.applicationType == 'team'"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> This is a team-based activity </label>
                            </div>

                            <div class="marginBottom15 marginLeft20">
                                <label class="marginRight10">
                                    <template v-if="form.model.applicationIsSyncToTeamQuestion"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Team members will see what the team reporter sees </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.others.isApplicationGraded"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> This activity is graded </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.applicationAllowStudentsToUploadFiles"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack">
                                    <template v-if="form.model.others.applicationType == 'team'"> Teams </template>
                                    <template v-else> Students </template> can upload files
                                </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.applicationAllowChangeAnswersBeforeFinalSubmission"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack">
                                    <template v-if="form.model.others.applicationType == 'team'"> Teams </template>
                                    <template v-else> Students </template> can change their answers
                                </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="!form.model.applicationAllowViewQuestionsAhead"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack">
                                    <template v-if="form.model.others.applicationType == 'team'"> Teams </template>
                                    <template v-else> Students </template> must answer questions in sequence
                                </label>
                            </div>

                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.applicationConfidenceBasedTesting"> yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Use Confidence-based Testing for MCQ </label>
                            </div>

                            <div v-if="!hideSafeExamBrowserField" class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.applicationAllowSafeExamBrowserOnly"> yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Students must answer using Safe Exam Browser </label>
                            </div>
                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.applicationAllowPdfDownload"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Allow students to download and/or print PDF documents </label>
                            </div>
                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.applicationEnableImagePointerEvents"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack">
                                    <template v-if="form.model.others.applicationType == 'team'"> Teams </template>
                                    <template v-else> Students </template> to download and/or print images from questions
                                </label>
                            </div>
                            <div v-if="hasMcqmQuestions">
                                <label class="marginRight10">
                                    <template v-if="form.model.applicationShowRequiredOptionsForMcqm"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> For MCQM, require to select exact number of correct answers </label>
                            </div>
                        </div>

                        <div class="marginTop30">
                            <div>
                                <h3 class="control-label">Instruction</h3>
                                <template v-if="form.model.applicationInstruction">
                                    <div v-html="form.model.applicationInstruction" />
                                </template>
                                <template v-else>
                                    <div>-</div>
                                </template>
                            </div>

                            <div class="marginTop15">
                                <h3 class="control-label">Attachments</h3>
                                <div>
                                    <div v-if="form.model.applicationAttachments.length != 0" class="flex align-items">
                                        <div class="col-xs-12">
                                            <div v-for="(file, idx) in form.model.applicationAttachments" :key="idx" class="flexSpaceBetween">
                                                <div>
                                                    <span>{{ idx + 1 }}.&nbsp;</span>
                                                    <span class="marginRight5">
                                                        <i class="fa fa-file-image-o" aria-hidden="true" />
                                                    </span>
                                                    <a :href="file.url" target="_blank" download>{{ file.filename }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-else>
                                        <div>-</div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </kr-panel>

        <kr-panel v-if="['evaluation', 'michaelsenEvaluation'].includes(activityObj.type)" :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <div class="col-xs-12 col-sm-6">
                    <h2>Interaction Settings</h2>
                </div>

                <div v-if="pageType == 'confirmation'" class="col-xs-12 col-sm-6 flex gap10" :class="{ justifyEnd: !isBelow768px }">
                    <template v-if="evaluationMode == 'edit'">
                        <button class="btn btn-success margin0 flexInherit" @click.prevent="submitActivitySettings('evaluation')">Update</button>
                        <button class="btn btn-default margin0 flexInherit" exact @click.prevent="evaluationMode = 'view'">Cancel</button>
                    </template>
                    <template v-if="evaluationMode == 'view'">
                        <button
                            class="btn btn-default margin0 flexInherit"
                            @click.prevent="
                                evaluationMode = 'edit';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                        </button>
                    </template>
                </div>
            </template>
            <template #content>
                <template v-if="!isSet && evaluationMode == 'view' && pageType == 'confirmation'"> Not Set </template>
                <template v-else>
                    <div v-if="pageType == 'form' || (pageType == 'confirmation' && evaluationMode == 'edit')">
                        <div v-if="activityObj.type == 'evaluation'">
                            <kr-toggle
                                :options="{ reverse: true }"
                                name="evaluationEnableMichaelsenPointDistribution"
                                display="Michaelsen Point Distribution"
                                :form="form"
                            />
                        </div>
                        <div v-if="form.model.evaluationEnableMichaelsenPointDistribution" class="col-xs-12 marginTop10 marginBottom15">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <th>Section</th>
                                        <th>Team</th>
                                        <th>Team Size</th>
                                        <th>Points To Distribute</th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(team, team_idx) in form.model.evaluationMichaelsenPointDistribution" :key="team_idx">
                                            <td>{{ team.section }}</td>
                                            <td>{{ team.name }}</td>
                                            <td>{{ team.members.length }}</td>
                                            <td>{{ team.point }}</td>
                                        </tr>
                                        <tr v-if="form.model.evaluationMichaelsenPointDistribution.length == 0">
                                            <td colspan="4" style="text-align: center">NO TEAM</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <template
                            v-if="
                                activityObj.type == 'michaelsenEvaluation' ||
                                (activityObj.type == 'evaluation' && form.model.evaluationEnableMichaelsenPointDistribution)
                            "
                        >
                            <div>
                                <kr-field
                                    :options="{ type: 'number' }"
                                    name="evaluationPointsAllocatedPerMember"
                                    display="How many points are allocated per member?"
                                    :form="form"
                                    @changed="initMichaelsenTable"
                                />
                            </div>
                            <div class="marginBottom15">
                                <kr-toggle
                                    :options="{ reverse: true }"
                                    name="evaluationShouldDistributePointsWithoutRepeatingValues"
                                    display="Students must distribute points without repeating values"
                                    :form="form"
                                />
                            </div>
                            <div class="marginBottom15">
                                <kr-toggle
                                    :options="{ reverse: true }"
                                    name="evaluationShouldDistributeAllPoints"
                                    display="Students must distribute ALL points"
                                    :form="form"
                                />
                            </div>
                            <div class="marginBottom15">
                                <kr-toggle
                                    :disabled="form.model.evaluationPointsAllocatedPerMember != 10 ? true : undefined"
                                    :options="{ reverse: true }"
                                    name="evaluationShouldGiveOneTeammateAboveTenPoints"
                                    display="Students must give at least one teammate a point above 10"
                                    :form="form"
                                />
                            </div>
                            <div class="marginBottom15">
                                <kr-toggle
                                    :disabled="form.model.evaluationPointsAllocatedPerMember != 10 ? true : undefined"
                                    :options="{ reverse: true }"
                                    name="evaluationShouldGiveOneTeammateBelowTenPoints"
                                    display="Students must give at least one teammate a point below 10"
                                    :form="form"
                                />
                            </div>
                            <div>
                                <kr-toggle
                                    :options="{ reverse: true }"
                                    name="evaluationAllowToGiveThemselvesPoints"
                                    display="Allow students to give points to themselves"
                                    :form="form"
                                />
                            </div>
                        </template>
                        <div class="marginBottom15 marginTop15 flex">
                            <div class="checkboxField marginRight10">
                                <input
                                    id="evaluationEnableImagePointerEvents"
                                    v-model="form.model.evaluationEnableImagePointerEvents"
                                    type="checkbox"
                                    class="checkbox-field"
                                    @input="waitToSave()"
                                />
                            </div>
                            <label for="evaluationEnableImagePointerEvents" class="control-label marginBottom0 paddingTop2">
                                Allow students to download and/or print images from questions
                            </label>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="activityObj.type == 'evaluation'">
                            <label class="marginRight10">
                                <template v-if="form.model.evaluationEnableMichaelsenPointDistribution"> Yes </template>
                                <template v-else>No</template>
                            </label>
                            <label class="control-label fontBlack"> Michaelsen Point Distribution </label>
                        </div>
                        <div v-if="form.model.evaluationEnableMichaelsenPointDistribution" class="col-xs-12 marginTop10 marginBottom15">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <th>Section</th>
                                        <th>Team</th>
                                        <th>Team Size</th>
                                        <th>Points To Distribute</th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(team, team_idx) in form.model.evaluationMichaelsenPointDistribution" :key="team_idx">
                                            <td>{{ team.section }}</td>
                                            <td>{{ team.name }}</td>
                                            <td>{{ team.members.length }}</td>
                                            <td>{{ team.point }}</td>
                                        </tr>
                                        <tr v-if="form.model.evaluationMichaelsenPointDistribution.length == 0">
                                            <td colspan="4" style="text-align: center">NO TEAM</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <template
                            v-if="
                                activityObj.type == 'michaelsenEvaluation' ||
                                (activityObj.type == 'evaluation' && form.model.evaluationEnableMichaelsenPointDistribution)
                            "
                        >
                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    {{ form.model.evaluationPointsAllocatedPerMember }}
                                </label>
                                <label class="control-label fontBlack"> How many points are allocated per member? </label>
                            </div>
                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.evaluationShouldDistributePointsWithoutRepeatingValues"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Students must distribute points without repeating values </label>
                            </div>
                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.evaluationShouldDistributeAllPoints"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Students must distribute ALL points </label>
                            </div>
                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.evaluationShouldGiveOneTeammateAboveTenPoints"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Students must give at least one teammate a point above 10 </label>
                            </div>
                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.evaluationShouldGiveOneTeammateBelowTenPoints"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Students must give at least one teammate a point below 10 </label>
                            </div>
                            <div class="marginBottom15">
                                <label class="marginRight10">
                                    <template v-if="form.model.evaluationAllowToGiveThemselvesPoints"> Yes </template>
                                    <template v-else>No</template>
                                </label>
                                <label class="control-label fontBlack"> Allow students to give points to themselves </label>
                            </div>
                        </template>
                        <div>
                            <label class="marginRight10">
                                <template v-if="form.model.evaluationEnableImagePointerEvents"> Yes </template>
                                <template v-else>No</template>
                            </label>
                            <label class="control-label fontBlack"> Allow students to download and/or print images from questions</label>
                        </div>
                    </div>
                </template>
            </template>
        </kr-panel>

        <kr-panel v-if="activityObj.type == 'evaluation'" :with-footer="false" :options="{ canToggle: false }" :class="{ marginTop20: pageType != 'form' }">
            <template #title>
                <div class="col-xs-12 col-sm-6">
                    <h2>Instructions To Students</h2>
                </div>

                <div v-if="pageType == 'confirmation'" class="col-xs-12 col-sm-6 flex gap10" :class="{ justifyEnd: !isBelow768px }">
                    <template v-if="evaluationMode == 'edit'">
                        <button class="btn btn-success margin0 flexInherit" @click.prevent="submitActivitySettings('evaluation')">Update</button>
                        <button class="btn btn-default margin0 flexInherit" exact @click.prevent="evaluationMode = 'view'">Cancel</button>
                    </template>
                    <template v-if="evaluationMode == 'view'">
                        <button
                            class="btn btn-default margi0 flexInherit"
                            @click.prevent="
                                evaluationMode = 'edit';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                        </button>
                    </template>
                </div>
            </template>
            <template #content>
                <template v-if="!isSet && evaluationMode == 'view' && pageType == 'confirmation'"> Not Set </template>
                <template v-else>
                    <div v-if="pageType == 'form' || evaluationMode == 'edit'">
                        <kr-field
                            :form="form"
                            name="evaluationInstruction"
                            display="Instruction"
                            :options="{
                                htmlEditor: true,
                                placeholder: 'Enter instructions for this activity here',
                                hasLabel: false,
                                showError: false,
                            }"
                        />
                    </div>
                    <div v-else>
                        <h3 class="control-label">Instruction</h3>
                        <template v-if="form.model.evaluationInstruction">
                            <div v-html="form.model.evaluationInstruction" />
                        </template>
                        <template v-else>
                            <div>-</div>
                        </template>
                    </div>

                    <div v-if="pageType == 'form' || evaluationMode == 'edit'" class="marginTop15">
                        <kr-file-multiple :form="form" :options="{ reverse: true }" name="evaluationAttachments" @preview-attachment="showAttachment" />
                    </div>
                    <div v-else class="marginTop15">
                        <h3 class="control-label">Attachments</h3>
                        <div>
                            <div v-if="form.model.evaluationAttachments.length != 0" class="flex align-items">
                                <div class="col-xs-12">
                                    <div v-for="(file, idx) in form.model.evaluationAttachments" :key="idx" class="flexSpaceBetween">
                                        <div>
                                            <span>{{ idx + 1 }}.&nbsp;</span>
                                            <span class="marginRight5">
                                                <i class="fa fa-file-image-o" aria-hidden="true" />
                                            </span>
                                            <a :href="file.url" target="_blank" download>{{ file.filename }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template v-else>
                                <div>-</div>
                            </template>
                        </div>
                    </div>
                </template>
            </template>
        </kr-panel>

        <div v-if="pageType == 'form'" class="marginTop10">
            <button class="btn btn-success" @click.prevent="submitActivitySettings()">
                Review your Activity<i class="fas fa-caret-right marginLeft8" aria-hidden="true" />
            </button>
            <button class="btn btn-default" @click.prevent="saveAndExit"><i class="fa fa-floppy-o marginRight8" aria-hidden="true" />Save and Exit</button>
        </div>

        <div id="previewModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="previewModal-title">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="previewModal-title" class="modal-title">Preview Attachment</h2>
                    </div>
                    <div class="modal-body" style="height: 75vh">
                        <div class="row" style="height: 100%">
                            <div class="col-xs-12" style="text-align: center; height: 100%">
                                <iframe :src="preview_url" width="100%" height="100%" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import krRadioMultiple from '../../../../../../components/forms/components/kr-radio-multiple.vue';
import KrForm from '../../../../../../components/forms/form';
import KrAuth from '../../../../../../components/auth/auth';
export default {
    components: { krRadioMultiple },
    props: ['activityObj', 'options'],
    data() {
        return {
            component_done_loading: false,
            preview_url: '',
            auth: new KrAuth(),
            form: new KrForm({
                fields: {
                    allowViewQuestionsAhead: '', //peerEvaluationV2
                    allowChangeAnswersBeforeFinalSubmission: '',
                    allowViewPreviousQuestions: '',

                    iratType: '',
                    iratConfidenceBasedTesting: '',
                    iratAllowStudentsToUploadFiles: '',
                    iratLimitTimeByPages: '',
                    iratAllowChangeAnswersBeforeFinalSubmission: '',
                    iratAllowViewPreviousQuestions: '',
                    iratAllowViewQuestionsAhead: '',
                    iratInstructionIsHTML: '',
                    iratInstruction: '',
                    iratAttachments: 'type!:array',
                    iratRandomizeAnswerOptionOrder: '',
                    iratHideQuestionOptionLabels: '',
                    iratImmediateFeedback: '',
                    iratEnableHintAndExplanation: '',
                    iratAllowSafeExamBrowserOnly: '',
                    iratAllowPdfDownload: '',
                    iratEnableImagePointerEvents: '',
                    iratShowExplanationsAfterTestHasCompleted: '',
                    iratShowHintsAndExplanationsDuringTest: '',

                    tratType: '',
                    tratAllowStudentsToUploadFiles: '',
                    tratLimitTimeByPages: '',
                    tratAllowChangeAnswersBeforeFinalSubmission: '',
                    tratAllowViewQuestionsAhead: '',
                    tratAllowViewPreviousQuestions: '',
                    tratInstructionIsHTML: '',
                    tratInstruction: '',
                    tratAttachments: 'type!:array',
                    tratRandomizeAnswerOptionOrder: '',
                    tratHideQuestionOptionLabels: '',
                    tratAllowTeamClarifications: '',
                    tratIsSyncToTeamQuestion: '',
                    tratEnableHintAndExplanation: '',
                    tratAllowSafeExamBrowserOnly: '',
                    tratAllowPdfDownload: '',
                    tratEnableImagePointerEvents: '',
                    tratShowExplanationsAfterTestHasCompleted: '',
                    tratShowHintsAndExplanationsDuringTest: '',

                    preworkType: 'default!:synchronous',
                    preworkStartDate: '',
                    preworkEndDate: '',
                    preworkInstructionIsHTML: '',
                    preworkInstruction: '',
                    preworkAttachments: 'type!:array',
                    preworkAllowPdfDownload: '',

                    applicationType: '',
                    applicationAllowStudentsToUploadFiles: '',
                    applicationLimitTimeByPages: '',
                    applicationAllowChangeAnswersBeforeFinalSubmission: '',
                    applicationAllowViewQuestionsAhead: '',
                    applicationAllowViewPreviousQuestions: '',
                    applicationInstructionIsHTML: '',
                    applicationInstruction: '',
                    applicationAttachments: 'type!:array',
                    applicationIsSyncToTeamQuestion: '',
                    applicationConfidenceBasedTesting: '',
                    applicationRandomizeAnswerOptionOrder: '',
                    applicationHideQuestionOptionLabels: '',
                    isApplicationGraded: '',
                    applicationAllowSafeExamBrowserOnly: '',
                    applicationAllowPdfDownload: '',
                    applicationEnableImagePointerEvents: '',
                    applicationShowRequiredOptionsForMcqm: '',

                    evaluationEnableMichaelsenPointDistribution: '',
                    evaluationMichaelsenPointDistribution: 'default!:array',
                    evaluationInstructionIsHTML: '',
                    evaluationInstruction: '',
                    evaluationAttachments: 'type!:array',

                    evaluationPointsAllocatedPerMember: '',
                    evaluationShouldDistributePointsWithoutRepeatingValues: '',
                    evaluationShouldDistributeAllPoints: '',
                    evaluationShouldGiveOneTeammateAboveTenPoints: '',
                    evaluationShouldGiveOneTeammateBelowTenPoints: '',
                    evaluationAllowToGiveThemselvesPoints: '',
                    evaluationEnableImagePointerEvents: '',

                    // peer evaluation v2
                    peerEvaluationV2InstructionIsHTML: '',
                    peerEvaluationV2Instruction: '',
                    peerEvaluationV2Attachments: 'type!:array',

                    others: 'type!:object',
                },
                props: {
                    successMessage: false,
                },
                urls: {
                    post: 'activities',
                },
            }),
            pageType: 'form',
            iratMode: 'view',
            tratMode: 'view',
            iratandtratMode: 'view',
            applicationMode: 'view',
            preworkMode: 'view',
            evaluationMode: 'view',
            peerEvaluationV2Mode: 'view',
            isSet: true,
            teams: [],
            APP_URL: process.env.VUE_APP_APP_URL,
            timeout: null,
            waitTime: 1000,
            iframe: {
                loaded: false,
                hideBanner: false,
            },
        };
    },
    computed: {
        hideSafeExamBrowserField() {
            if (
                process.env.VUE_APP_ENV == 'staging' ||
                (process.env.VUE_APP_ENV == 'production' &&
                    [
                        '08299f88-6223-49aa-84e7-1958d703de6e',
                        'c5a47ef7-eba8-4d7c-805c-3b6cc6abb53c',
                        '00524266-6661-44a3-a2ee-6b5b36ddc286',
                        '436b4f1e-1e48-41fe-845f-9655c2a56646',
                        'eba54676-816f-4eea-a3a2-d5c8ff69f122',
                    ].includes(this.auth.user().account.uuid))
            ) {
                return false;
            }
            return true;
        },
        hasMcqmQuestions() {
            if (this.activityObj.type == 'application') {
                for (var i = 0; i < this.activityObj.questions.length; i++) {
                    let group = this.activityObj.questions[i];
                    for (var j = 0; j < group.length; j++) {
                        if (group[j].type == 'mcqm') {
                            return true;
                        }
                    }
                }
                return false;
            } else {
                return false;
            }
        },
    },
    watch: {
        options: {
            handler: function () {
                this.pageType = _.get(this.options, 'pageType', 'form');
            },
            deep: true,
        },
    },
    mounted() {
        Vue.nextTick(function () {
            $('#irat_iframe').contents().find('.community-banner-container').hide();
            $('#trat_iframe').contents().find('.community-banner-container').hide();
            $('#application_iframe').contents().find('.community-banner-container').hide();
            $('#peerEvaluationV2_iframe').contents().find('.community-banner-container').hide();
        });
    },
    created() {
        this.pageType = _.get(this.options, 'pageType', 'form');
        this.init();
    },
    methods: {
        fetchTeams() {
            return axios.get('/courses/' + this.activityObj.course.uuid + '/teams');
        },
        saveAndExit() {
            this.submitActivitySettings(false, true);
        },
        initMichaelsenTable() {
            if (this.form.model.evaluationPointsAllocatedPerMember != 10) {
                this.form.model.evaluationShouldGiveOneTeammateAboveTenPoints = false;
                this.form.model.evaluationShouldGiveOneTeammateBelowTenPoints = false;
            }
            this.form.model.evaluationMichaelsenPointDistribution = [];
            var teams = this.teams;
            for (var i = 0; i < teams.length; i++) {
                this.form.model.evaluationMichaelsenPointDistribution.push({
                    name: teams[i].name,
                    members: teams[i].members,
                    point:
                        teams[i].members.length != 0
                            ? (teams[i].members.length - (this.form.model.evaluationAllowToGiveThemselvesPoints ? 0 : 1)) *
                              this.form.model.evaluationPointsAllocatedPerMember
                            : 0,
                    section: teams[i].section,
                });
            }
        },
        init() {
            if (!this.activityObj.isActivitySettingsCompleted) {
                this.isSet = false;
            } else {
                this.isSet = true;
            }

            if (this.activityObj) {
                this.form.model.others = this.activityObj.others;
            }
            if (this.activityObj.type == 'peerEvaluationV2') {
                this.form.model.allowViewPreviousQuestions = _.get(this.activityObj, 'settings.peerEvaluationV2.allowViewPreviousQuestions', true);
                this.form.model.allowViewQuestionsAhead = _.get(this.activityObj, 'settings.peerEvaluationV2.allowViewQuestionsAhead', true);
                this.form.model.allowChangeAnswersBeforeFinalSubmission = _.get(
                    this.activityObj,
                    'settings.peerEvaluationV2.allowChangeAnswersBeforeFinalSubmission',
                    true
                );
                this.form.model.peerEvaluationV2InstructionIsHTML = _.get(this.activityObj, 'settings.peerEvaluationV2.instructionIsHTML', true);
                this.form.model.peerEvaluationV2Instruction = _.get(this.activityObj, 'settings.peerEvaluationV2.instruction', '');
                this.form.model.peerEvaluationV2Attachments = _.get(this.activityObj, 'settings.peerEvaluationV2.attachments', []);
            }
            if (this.activityObj.type == 'prework') {
                this.form.model.preworkAllowPdfDownload = _.get(this.activityObj, 'settings.prework.allowPdfDownload', false);
            }
            if (this.activityObj.type == 'irat') {
                this.form.model.iratType = _.get(this.activityObj, 'settings.irat.type', 'synchronous');
                this.form.model.iratConfidenceBasedTesting = _.get(this.activityObj, 'settings.irat.confidenceBasedTesting', false);
                this.form.model.iratAllowStudentsToUploadFiles = _.get(this.activityObj, 'settings.irat.allowStudentsToUploadFiles', true);
                this.form.model.iratLimitTimeByPages = _.get(this.activityObj, 'settings.irat.limitTimeByPages', false);
                this.form.model.iratAllowChangeAnswersBeforeFinalSubmission = _.get(
                    this.activityObj,
                    'settings.irat.allowChangeAnswersBeforeFinalSubmission',
                    true
                );
                this.form.model.iratAllowViewPreviousQuestions = _.get(this.activityObj, 'settings.irat.allowViewPreviousQuestions', true);
                this.form.model.iratAllowViewQuestionsAhead = _.get(this.activityObj, 'settings.irat.allowViewQuestionsAhead', true);
                this.form.model.iratInstructionIsHTML = _.get(this.activityObj, 'settings.irat.instructionIsHTML', true);
                this.form.model.iratInstruction = _.get(this.activityObj, 'settings.irat.instruction', '');
                this.form.model.iratAttachments = _.get(this.activityObj, 'settings.irat.attachments', []);
                this.form.model.iratRandomizeAnswerOptionOrder = _.get(this.activityObj, 'settings.irat.randomizeAnswerOptionOrder', false);
                this.form.model.iratHideQuestionOptionLabels = _.get(this.activityObj, 'settings.irat.hideQuestionOptionLabels', false);
                this.form.model.iratImmediateFeedback = _.get(this.activityObj, 'settings.irat.immediateFeedback', false);
                this.form.model.iratEnableHintAndExplanation = _.get(this.activityObj, 'settings.irat.enableHintAndExplanation', false);
                this.form.model.iratAllowSafeExamBrowserOnly = _.get(this.activityObj, 'settings.irat.allowSafeExamBrowserOnly', false);
                this.form.model.iratAllowPdfDownload = _.get(this.activityObj, 'settings.irat.allowPdfDownload', false);
                this.form.model.iratEnableImagePointerEvents = _.get(this.activityObj, 'settings.irat.enableImagePointerEvents', true);
                this.form.model.iratShowExplanationsAfterTestHasCompleted = _.get(
                    this.activityObj,
                    'settings.irat.showExplanationsAfterTestHasCompleted',
                    true
                );
                this.form.model.iratShowHintsAndExplanationsDuringTest = _.get(this.activityObj, 'settings.irat.showHintsAndExplanationsDuringTest', false);
            }
            if (this.activityObj.type == 'trat') {
                this.form.model.tratType = _.get(this.activityObj, 'settings.trat.type', 'synchronous');
                this.form.model.tratAllowStudentsToUploadFiles = _.get(this.activityObj, 'settings.trat.allowStudentsToUploadFiles', true);
                this.form.model.tratLimitTimeByPages = _.get(this.activityObj, 'settings.trat.limitTimeByPages', false);
                this.form.model.tratAllowChangeAnswersBeforeFinalSubmission = _.get(
                    this.activityObj,
                    'settings.trat.allowChangeAnswersBeforeFinalSubmission',
                    true
                );
                this.form.model.tratAllowViewPreviousQuestions = _.get(this.activityObj, 'settings.trat.allowViewPreviousQuestions', true);
                this.form.model.tratAllowViewQuestionsAhead = _.get(this.activityObj, 'settings.trat.allowViewQuestionsAhead', true);
                this.form.model.tratInstructionIsHTML = _.get(this.activityObj, 'settings.trat.instructionIsHTML', true);
                this.form.model.tratInstruction = _.get(this.activityObj, 'settings.trat.instruction', '');
                this.form.model.tratAttachments = _.get(this.activityObj, 'settings.trat.attachments', []);
                this.form.model.tratRandomizeAnswerOptionOrder = _.get(this.activityObj, 'settings.trat.randomizeAnswerOptionOrder', false);
                this.form.model.tratHideQuestionOptionLabels = _.get(this.activityObj, 'settings.trat.hideQuestionOptionLabels', false);
                this.form.model.tratAllowTeamClarifications = _.get(this.activityObj, 'settings.trat.allowTeamClarifications', true);
                this.form.model.tratIsSyncToTeamQuestion = _.get(this.activityObj, 'settings.trat.isSyncToTeamQuestion', true);
                this.form.model.tratEnableHintAndExplanation = _.get(this.activityObj, 'settings.trat.enableHintAndExplanation', false);
                this.form.model.tratAllowSafeExamBrowserOnly = _.get(this.activityObj, 'settings.trat.allowSafeExamBrowserOnly', false);
                this.form.model.tratAllowPdfDownload = _.get(this.activityObj, 'settings.trat.allowPdfDownload', false);
                this.form.model.tratEnableImagePointerEvents = _.get(this.activityObj, 'settings.trat.enableImagePointerEvents', true);
                this.form.model.tratShowExplanationsAfterTestHasCompleted = _.get(
                    this.activityObj,
                    'settings.trat.showExplanationsAfterTestHasCompleted',
                    true
                );
                this.form.model.tratShowHintsAndExplanationsDuringTest = _.get(this.activityObj, 'settings.trat.showHintsAndExplanationsDuringTest', false);
            }
            if (this.activityObj.type == 'iratntrat') {
                this.form.model.iratType = _.get(this.activityObj, 'settings.irat.type', 'synchronous');
                this.form.model.iratConfidenceBasedTesting = _.get(this.activityObj, 'settings.irat.confidenceBasedTesting', false);
                this.form.model.iratAllowStudentsToUploadFiles = _.get(this.activityObj, 'settings.irat.allowStudentsToUploadFiles', true);
                this.form.model.iratLimitTimeByPages = _.get(this.activityObj, 'settings.irat.limitTimeByPages', false);
                this.form.model.iratAllowChangeAnswersBeforeFinalSubmission = _.get(
                    this.activityObj,
                    'settings.irat.allowChangeAnswersBeforeFinalSubmission',
                    true
                );
                this.form.model.iratAllowViewPreviousQuestions = _.get(this.activityObj, 'settings.irat.allowViewPreviousQuestions', true);
                this.form.model.iratAllowViewQuestionsAhead = _.get(this.activityObj, 'settings.irat.allowViewQuestionsAhead', true);
                this.form.model.iratInstructionIsHTML = _.get(this.activityObj, 'settings.irat.instructionIsHTML', true);
                this.form.model.iratInstruction = _.get(this.activityObj, 'settings.irat.instruction', '');
                this.form.model.iratAttachments = _.get(this.activityObj, 'settings.irat.attachments', []);
                this.form.model.iratRandomizeAnswerOptionOrder = _.get(this.activityObj, 'settings.irat.randomizeAnswerOptionOrder', false);
                this.form.model.iratHideQuestionOptionLabels = _.get(this.activityObj, 'settings.irat.hideQuestionOptionLabels', false);
                this.form.model.iratImmediateFeedback = _.get(this.activityObj, 'settings.irat.immediateFeedback', false);
                this.form.model.iratEnableHintAndExplanation = _.get(this.activityObj, 'settings.irat.enableHintAndExplanation', false);
                this.form.model.iratAllowSafeExamBrowserOnly = _.get(this.activityObj, 'settings.irat.allowSafeExamBrowserOnly', false);
                this.form.model.iratAllowPdfDownload = _.get(this.activityObj, 'settings.irat.allowPdfDownload', false);
                this.form.model.iratEnableImagePointerEvents = _.get(this.activityObj, 'settings.irat.enableImagePointerEvents', true);
                this.form.model.iratShowExplanationsAfterTestHasCompleted = _.get(
                    this.activityObj,
                    'settings.irat.showExplanationsAfterTestHasCompleted',
                    true
                );
                this.form.model.iratShowHintsAndExplanationsDuringTest = _.get(this.activityObj, 'settings.irat.showHintsAndExplanationsDuringTest', false);

                this.form.model.tratType = _.get(this.activityObj, 'settings.trat.type', 'synchronous');
                this.form.model.tratAllowStudentsToUploadFiles = _.get(this.activityObj, 'settings.trat.allowStudentsToUploadFiles', true);
                this.form.model.tratLimitTimeByPages = _.get(this.activityObj, 'settings.trat.limitTimeByPages', false);
                this.form.model.tratAllowChangeAnswersBeforeFinalSubmission = _.get(
                    this.activityObj,
                    'settings.trat.allowChangeAnswersBeforeFinalSubmission',
                    true
                );
                this.form.model.tratAllowViewPreviousQuestions = _.get(this.activityObj, 'settings.trat.allowViewPreviousQuestions', true);
                this.form.model.tratAllowViewQuestionsAhead = _.get(this.activityObj, 'settings.trat.allowViewQuestionsAhead', true);
                this.form.model.tratInstructionIsHTML = _.get(this.activityObj, 'settings.trat.instructionIsHTML', true);
                this.form.model.tratInstruction = _.get(this.activityObj, 'settings.trat.instruction', '');
                this.form.model.tratAttachments = _.get(this.activityObj, 'settings.trat.attachments', []);
                this.form.model.tratRandomizeAnswerOptionOrder = _.get(this.activityObj, 'settings.trat.randomizeAnswerOptionOrder', false);
                this.form.model.tratHideQuestionOptionLabels = _.get(this.activityObj, 'settings.trat.hideQuestionOptionLabels', false);
                this.form.model.tratAllowTeamClarifications = _.get(this.activityObj, 'settings.trat.allowTeamClarifications', true);
                this.form.model.tratIsSyncToTeamQuestion = _.get(this.activityObj, 'settings.trat.isSyncToTeamQuestion', true);
                this.form.model.tratEnableHintAndExplanation = _.get(this.activityObj, 'settings.trat.enableHintAndExplanation', false);
                this.form.model.tratAllowSafeExamBrowserOnly = _.get(this.activityObj, 'settings.trat.allowSafeExamBrowserOnly', false);
                this.form.model.tratAllowPdfDownload = _.get(this.activityObj, 'settings.trat.allowPdfDownload', false);
                this.form.model.tratEnableImagePointerEvents = _.get(this.activityObj, 'settings.trat.enableImagePointerEvents', true);
                this.form.model.tratShowExplanationsAfterTestHasCompleted = _.get(
                    this.activityObj,
                    'settings.trat.showExplanationsAfterTestHasCompleted',
                    true
                );
                this.form.model.tratShowHintsAndExplanationsDuringTest = _.get(this.activityObj, 'settings.trat.showHintsAndExplanationsDuringTest', false);
            }
            if (this.activityObj.type == 'prework') {
                this.form.model.preworkType = _.get(this.activityObj, 'settings.prework.type', 'synchronous');
                this.form.model.preworkInstructionIsHTML = _.get(this.activityObj, 'settings.prework.instructionIsHTML', true);
                this.form.model.preworkInstruction = _.get(this.activityObj, 'settings.prework.instruction', '');
                this.form.model.preworkAttachments = _.get(this.activityObj, 'settings.prework.attachments', []);
            }
            if (this.activityObj.type == 'application') {
                this.form.model.applicationType = _.get(this.activityObj, 'settings.application.type', 'synchronous');
                this.form.model.applicationAllowStudentsToUploadFiles = _.get(this.activityObj, 'settings.application.allowStudentsToUploadFiles', true);
                this.form.model.applicationLimitTimeByPages = _.get(this.activityObj, 'settings.application.limitTimeByPages', false);
                this.form.model.applicationAllowChangeAnswersBeforeFinalSubmission = _.get(
                    this.activityObj,
                    'settings.application.allowChangeAnswersBeforeFinalSubmission',
                    true
                );
                this.form.model.applicationAllowViewPreviousQuestions = _.get(this.activityObj, 'settings.application.allowViewPreviousQuestions', true);
                this.form.model.applicationAllowViewQuestionsAhead = _.get(this.activityObj, 'settings.application.allowViewQuestionsAhead', true);
                this.form.model.applicationInstructionIsHTML = _.get(this.activityObj, 'settings.application.instructionIsHTML', true);
                this.form.model.applicationInstruction = _.get(this.activityObj, 'settings.application.instruction', '');
                this.form.model.applicationAttachments = _.get(this.activityObj, 'settings.application.attachments', []);
                this.form.model.applicationIsSyncToTeamQuestion = _.get(this.activityObj, 'settings.application.isSyncToTeamQuestion', true);
                this.form.model.applicationConfidenceBasedTesting = _.get(this.activityObj, 'settings.application.confidenceBasedTesting', false);
                this.form.model.applicationRandomizeAnswerOptionOrder = _.get(this.activityObj, 'settings.application.randomizeAnswerOptionOrder', false);
                this.form.model.applicationHideQuestionOptionLabels = _.get(this.activityObj, 'settings.application.hideQuestionOptionLabels', false);
                this.form.model.others.isApplicationGraded = _.get(this.activityObj, 'others.isApplicationGraded', true);
                this.form.model.others.applicationType = _.get(this.activityObj, 'others.applicationType', true);
                this.form.model.applicationAllowSafeExamBrowserOnly = _.get(this.activityObj, 'settings.application.allowSafeExamBrowserOnly', false);
                this.form.model.applicationAllowPdfDownload = _.get(this.activityObj, 'settings.application.allowPdfDownload', false);
                this.form.model.applicationEnableImagePointerEvents = _.get(this.activityObj, 'settings.application.enableImagePointerEvents', true);
                this.form.model.applicationShowRequiredOptionsForMcqm = _.get(this.activityObj, 'settings.application.showRequiredOptionsForMcqm', false);
            }
            if (this.activityObj.type == 'evaluation') {
                this.form.model.evaluationEnableMichaelsenPointDistribution = _.get(
                    this.activityObj,
                    'settings.evaluation.enableMichaelsenPointDistribution',
                    true
                );
                this.form.model.evaluationInstructionIsHTML = _.get(this.activityObj, 'settings.evaluation.instructionIsHTML', true);
                this.form.model.evaluationInstruction = _.get(this.activityObj, 'settings.evaluation.instruction', '');
                this.form.model.evaluationAttachments = _.get(this.activityObj, 'settings.evaluation.attachments', []);
                this.form.model.evaluationInstructionIsHTML = _.get(this.activityObj, 'settings.evaluation.instructionIsHTML', true);
                this.form.model.evaluationInstruction = _.get(this.activityObj, 'settings.evaluation.instruction', '');
                this.form.model.evaluationAttachments = _.get(this.activityObj, 'settings.evaluation.attachments', []);

                this.form.model.evaluationPointsAllocatedPerMember = _.get(this.activityObj, 'settings.evaluation.pointsAllocatedPerMember', 10);
                this.form.model.evaluationShouldDistributePointsWithoutRepeatingValues = _.get(
                    this.activityObj,
                    'settings.evaluation.shouldDistributePointsWithoutRepeatingValues',
                    true
                );
                this.form.model.evaluationShouldDistributeAllPoints = _.get(this.activityObj, 'settings.evaluation.shouldDistributeAllPoints', true);
                this.form.model.evaluationShouldGiveOneTeammateAboveTenPoints = _.get(
                    this.activityObj,
                    'settings.evaluation.shouldGiveOneTeammateAboveTenPoints',
                    true
                );
                this.form.model.evaluationShouldGiveOneTeammateBelowTenPoints = _.get(
                    this.activityObj,
                    'settings.evaluation.shouldGiveOneTeammateBelowTenPoints',
                    true
                );
                this.form.model.evaluationAllowToGiveThemselvesPoints = _.get(this.activityObj, 'settings.evaluation.allowToGiveThemselvesPoints', false);
                var that = this;
                that.form.model.evaluationMichaelsenPointDistribution = [];
                this.fetchTeams().then(function (response) {
                    that.teams = response.data.data;
                    that.initMichaelsenTable();
                    that.component_done_loading = true;
                });
                this.form.model.evaluationEnableImagePointerEvents = _.get(this.activityObj, 'settings.evaluation.enableImagePointerEvents', true);
            }
            if (this.activityObj.type == 'michaelsenEvaluation') {
                this.form.model.evaluationEnableMichaelsenPointDistribution = true;
                this.form.model.evaluationInstructionIsHTML = _.get(this.activityObj, 'settings.michaelsenEvaluation.instructionIsHTML', true);
                this.form.model.evaluationInstruction = _.get(this.activityObj, 'settings.michaelsenEvaluation.instruction', '');
                this.form.model.evaluationAttachments = _.get(this.activityObj, 'settings.michaelsenEvaluation.attachments', []);

                this.form.model.evaluationPointsAllocatedPerMember = _.get(this.activityObj, 'settings.michaelsenEvaluation.pointsAllocatedPerMember', 10);
                this.form.model.evaluationShouldDistributePointsWithoutRepeatingValues = _.get(
                    this.activityObj,
                    'settings.michaelsenEvaluation.shouldDistributePointsWithoutRepeatingValues',
                    true
                );
                this.form.model.evaluationShouldDistributeAllPoints = _.get(this.activityObj, 'settings.michaelsenEvaluation.shouldDistributeAllPoints', true);
                this.form.model.evaluationShouldGiveOneTeammateAboveTenPoints = _.get(
                    this.activityObj,
                    'settings.michaelsenEvaluation.shouldGiveOneTeammateAboveTenPoints',
                    true
                );
                this.form.model.evaluationShouldGiveOneTeammateBelowTenPoints = _.get(
                    this.activityObj,
                    'settings.michaelsenEvaluation.shouldGiveOneTeammateBelowTenPoints',
                    true
                );
                this.form.model.evaluationAllowToGiveThemselvesPoints = _.get(
                    this.activityObj,
                    'settings.michaelsenEvaluation.allowToGiveThemselvesPoints',
                    false
                );
                var that = this;
                this.fetchTeams().then(function (response) {
                    that.teams = response.data.data;
                    that.initMichaelsenTable();
                    that.component_done_loading = true;
                });
            } else {
                this.component_done_loading = true;
            }
        },
        showAttachment(url) {
            this.preview_url = url;
            $('#previewModal').modal('show');
        },
        submitActivitySettings(mode = 'irat', exitOnSave = false) {
            var that = this;
            let url = '/activities/' + this.activityObj.uuid + '/settings?settings=activity';
            // let url = "/activities/" + this.activityObj.uuid;
            if (this.form.model.iratAllowChangeAnswersBeforeFinalSubmission) {
                this.form.model.iratAllowViewPreviousQuestions = true;
            }

            if (
                this.form.model.applicationAllowChangeAnswersBeforeFinalSubmission ||
                (this.form.model.applicationAllowViewQuestionsAhead && !this.form.model.iratAllowChangeAnswersBeforeFinalSubmission)
            ) {
                this.form.model.applicationAllowViewPreviousQuestions = true;
            }
            let data = _.cloneDeep(this.form.model);
            if (this.activityObj.type == 'peerEvaluationV2') {
                url = '/activities/' + this.activityObj.uuid + '/peer-evaluation/settings';
                data = {
                    settings: {
                        allowViewPreviousQuestions: this.form.model.allowViewPreviousQuestions,
                        allowViewQuestionsAhead: this.form.model.allowViewQuestionsAhead,
                        allowChangeAnswersBeforeFinalSubmission: this.form.model.allowChangeAnswersBeforeFinalSubmission,
                        instructionIsHTML: this.form.model.peerEvaluationV2InstructionIsHTML,
                        instruction: this.form.model.peerEvaluationV2Instruction,
                        attachments: this.form.model.peerEvaluationV2Attachments,
                    },
                };
            }
            if (that.activityObj.type == 'application') {
                this.form.put('/activities/' + this.activityObj.uuid).then(function (response) {
                    if (!response.krFormError) {
                        that.$emit('activity-updated', response.data.data);
                    }
                });
            }

            this.form.put(url, { data: data }).then(function (response) {
                if (!response.krFormError) {
                    that.$emit('activity-updated', response.data.data);
                    if (exitOnSave === true) {
                        Vue.nextTick(function () {
                            that.$emit('save-and-exit');
                        });
                    } else {
                        if (that.pageType == 'form') {
                            Vue.nextTick(function () {
                                that.$emit('next-step');
                            });
                        } else {
                            if (mode == 'irat') {
                                that.iratMode = 'view';
                            }
                            if (mode == 'trat') {
                                that.tratMode = 'view';
                            }
                            if (mode == 'iratntrat') {
                                that.iratandtratMode = 'view';
                            }
                            if (mode == 'prework') {
                                that.preworkMode = 'view';
                            }
                            if (mode == 'application') {
                                that.applicationMode = 'view';
                            }
                            if (mode == 'evaluation') {
                                that.evaluationMode = 'view';
                            }
                            if (mode == 'peerEvaluationV2') {
                                that.peerEvaluationV2Mode = 'view';
                            }
                        }
                        Vue.nextTick(function () {
                            that.init();
                        });
                    }
                }
            });
        },
        waitToSave() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.autoSave.bind(this), this.waitTime);
        },
        autoSave() {
            var that = this;
            let url = '/activities/' + this.activityObj.uuid + '/settings?settings=activity';
            let data = _.cloneDeep(this.form.model);
            if (this.activityObj.type == 'peerEvaluationV2') {
                url = '/activities/' + this.activityObj.uuid + '/peer-evaluation/settings';
                data = {
                    settings: {
                        allowViewPreviousQuestions: this.form.model.allowViewPreviousQuestions,
                        allowViewQuestionsAhead: this.form.model.allowViewQuestionsAhead,
                        allowChangeAnswersBeforeFinalSubmission: this.form.model.allowChangeAnswersBeforeFinalSubmission,
                    },
                };
            }
            if (that.activityObj.type == 'application') {
                this.form.put('/activities/' + this.activityObj.uuid).then(function (response) {
                    document.getElementById(that.activityObj.type + '_iframe').src += '';
                });
            }

            this.form
                .put(url, { data: data })
                .then((response) => {
                    if (this.activityObj.type == 'iratntrat') {
                        var splitArray = that.activityObj.type.split('n');
                        for (var i = 0; i < splitArray.length; i++) {
                            document.getElementById(splitArray[i] + '_iframe').src += '';
                        }
                    } else {
                        document.getElementById(that.activityObj.type + '_iframe').src += '';
                    }
                })
                .catch((e) => {
                    console.log('Error... ');
                });
        },
        loadIframe() {
            this.iframe.loaded = true;
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.hideDiv.bind(this), 100);
        },
        hideDiv() {
            if (this.activityObj.type == 'iratntrat') {
                var splitArray = this.activityObj.type.split('n');
                for (var i = 0; i < splitArray.length; i++) {
                    var iframe = $('#' + splitArray[i] + '_iframe').contents();

                    iframe.find('body').removeClass('modal-open');
                    iframe.find('.community-banner-container').hide();
                    // iframe.find('.test_topBar').hide();
                    // iframe.find('.test_topBar_placeholder').hide();
                    iframe.find('.col-md-3.left_col.no-print').hide();
                    iframe.find('footer').hide();
                    iframe.find('#launcher').hide();
                    iframe.find('.statusBar').hide();
                    iframe.find('.right-container').attr('style', 'padding-top: 20px !important');
                    iframe.find('.statusBar ~ main.main').attr('style', 'padding:0; width:100%');
                    iframe.find('.test_content').attr('style', 'padding: 20px');
                }
            } else {
                var iframe = $('#' + this.activityObj.type + '_iframe').contents();

                iframe.find('body').removeClass('modal-open');
                iframe.find('.community-banner-container').hide();
                // iframe.find('.test_topBar').hide();
                // iframe.find('.test_topBar_placeholder').hide();
                iframe.find('.col-md-3.left_col.no-print').hide();
                iframe.find('footer').hide();
                iframe.find('#launcher').hide();
                iframe.find('.statusBar').hide();
                iframe.find('.right-container').attr('style', 'padding-top: 20px !important');
                iframe.find('.statusBar ~ main.main').attr('style', 'padding:0; width:100%');
                iframe.find('.test_content').attr('style', 'padding: 20px');
            }
        },
        /*changing from team to individidual*/
        changedApplicationType() {
            if (this.form.model.others.applicationType == 'team') {
                this.form.model.applicationIsSyncToTeamQuestion = true;
                this.form.model.applicationConfidenceBasedTesting = false;
            } else {
                this.form.model.applicationIsSyncToTeamQuestion = false;
            }
        },
        handleIframeFocusIn() {
            console.log('focus in');
            // Announce a message when the focus enters the iframe
            const messageArea = document.getElementById('message-area');
            messageArea.innerText = 'Focus entered the iframe';
            console.log('message updated');
        },
        handleIframeFocusOut() {
            // Announce a message when the focus exits the iframe
            const messageArea = document.getElementById('message-area');
            messageArea.innerText = 'Focus exited the iframe';
        },
    },
};
</script>

<style lang="scss" scoped>
.static-label-toggle {
    line-height: 10px;
    margin-left: 20px;
}
.optionDiv {
    margin-bottom: 15px;
}

.expectation-paragraph {
    border: 4px solid #0071be;
    max-width: 940px;
}
</style>
